import { fetchAuthSession } from "@aws-amplify/auth";
import Loader from "components/Common/Loader/Loader";
import PanelLayout from "components/layout";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { authRoutes } from "./routesPath/authRoutes";
import { patientRoutes } from "./routesPath/patientRoutes";

const Approutes = () => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAuthSession();
        console.log("data", response.tokens.accessToken.toString());
        if (response.tokens.accessToken.toString()) {
          console.log("i am in this condition");
          setAuthenticated(true); // Set authenticated to true if tokens are present
        }
      } catch (error) {
        // Handle error fetching authentication session
        console.error("Error fetching authentication session:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <Routes>
        {/* Render patient routes only if authenticated */}
        {authenticated &&
          patientRoutes.map((route, i) => (
            <Route
              key={i}
              exact
              path={route.path}
              element={
                <PanelLayout>
                  <route.component />
                </PanelLayout>
              }
            />
          ))}
        {/* Render auth routes always */}
        {authRoutes.map((route, i) => (
          <Route
            key={i}
            exact
            path={route.path}
            element={<route.component />}
          />
        ))}
        {/* Redirect to login if not authenticated */}
        {!authenticated && (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </Router>
  );
};

export default Approutes;
