import { IMAGES } from "utils/helper/Images";
import React from "react";
import styles from "style/Search.module.css";

const SearchBar = (props) => {
  const { onChange, customClass } = props;

  return (
    <div className={`${styles.searchBarWrapper} ${customClass}`}>
      <input
        type="text"
        placeholder="Search"
        className={`w-100`}
        onChange={onChange}
      />
      <img src={IMAGES.searchbarIcon} alt="search input" />
    </div>
  );
};

export default SearchBar;
