import { patientRoutes } from "utils/helper/Constants";
import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "utils/helper/Images";
import styles from "style/TopBar.module.css";

const TopBar = (props) => {
  const { navItemName, IconItemName, LoginBtn } = props;
  return (
    <div className={`${styles.mainTopBar}`}>
      <div className={`${styles.LeftSide}`}>
        <Link to="/" className={`d-inline-block ${styles.logoWrapper}`}>
          <img src={IMAGES.Logo} alt="MedFlow Logo" />
        </Link>
        <div
          className={`d-flex align-items-center text-white ${styles.menuItem}`}
        >
          <span className="ps-2">{navItemName}</span>
        </div>
        <div
          className={`d-flex align-items-center text-white ${styles.menuItem}`}
        >
          <img className={styles.HomeImg} src={IMAGES.HomeIcon} alt="home" />{" "}
          <span className="ps-2 d-inline-block">{IconItemName}</span>
        </div>
      </div>
      <div className={`${styles.RightSide}`}>
        <Link
          to="/login"
          className={`d-flex align-items-center text-white ${styles.LoginBtn}`}
        >
          <span className="ps-3 d-inline-block">{LoginBtn}</span>
        </Link>
      </div>
    </div>
  );
};
export default TopBar;
