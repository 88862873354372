import { createSlice } from "@reduxjs/toolkit";

// Initial State
export const INITIAL_STATE = {
  signUpData: null,
  loginData: null,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    // SignUp request reducers
    signUpRequest: (state) => {
      state.error = null; // Clear any previous error
    },
    // SignUp response reducers
    signUpSuccess(state, { payload }) {
      state.loginData = payload;
    },

    signUpFailure(state, { payload }) {
      state.error = payload;
    },
    // Login request reducers
    loginRequest: (state) => {
      state.error = null; // Clear any previous error
    },
    // Login response reducers
    loginSuccess(state, { payload }) {
      state.loginData = payload;
    },
    loginFailure(state, { payload }) {
      state.error = payload;
    },
    logoutRequest: (state) => {
      state.error = null; // Clear any previous error
    },
    // Logout response reducers
    logoutSuccess(state) {
      state.signUpData = null;
      state.loginData = null;
      state.error = null;
    },
    logoutFailure(state, { payload }) {
      state.error = payload;
    },
  },
});

export const {
  signUpRequest,
  signUpSuccess,
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
} = authSlice.actions;

export default authSlice.reducer;
