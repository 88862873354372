import { fetchAuthSession } from "aws-amplify/auth";
// import { userCompanyID } from "utils";
import { del, get, post, put } from "aws-amplify/api";
// const apiName = "apif79b844d";
// const publicApiName = "api4af2df69";

const apiName = "AdminQueries";
const publicApiName = "usersApi";

export const API_METHODS = {
  delete: "delete",
  get: "get",
  post: "post",
  put: "put",
};

export const callAmplifyAPI = async (
  path,
  payload,
  method = API_METHODS.post
) => {
  try {
    const token = `${(await fetchAuthSession()).tokens.accessToken.toString()}`;
    const params = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: { ...payload, clientID: userCompanyID.get() },
      body: { ...payload },
    };

    let api = null;
    // apiRes = await generateClient[method](apiName, path, params);
    // apiRes = await post({ apiName, path, options: params }).response;
    switch (method.toLowerCase()) {
      case "get":
        api = get({ apiName, path, options: params });
        break;
      case "post":
        api = post({ apiName, path, options: params });
        break;
      case "put":
        api = put({ apiName, path, options: params });
        break;
      case "delete":
        api = del({ apiName, path, options: params });
        break;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }

    const apiRes = await api.response;
    return apiRes.body.json();
  } catch (err) {
    throw Error(err);
  }
};
