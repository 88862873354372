import CustomButton from "components/Common/CustomButton/CustomButton";
import GoogleMaps from "components/Common/GoogleMaps/GoogleMaps";
import GooglePlacesAutoComplete from "components/Common/GooglePlacesAutoComplete/GooglePlacesAutoComplete";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import React, { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { patientRoutes } from "utils/helper/Constants";
import styles from "style/AddAddress.module.css";

const AddAddress = () => {
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const history = useNavigate();
  const navigateTo = (route) => {
    history(route);
  };
  const handlePickLocation = () => {
    if (navigator.geolocation) {
      // Get the current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLng(longitude);
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  };

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`w-100 ${styles.rightmainWrapper} `}>
        <Navbar
          color
          navlinkText="Appointments"
          navlinkText1="New Appointments"
          navlinkIcon={<AiFillHome />}
        />

        <div
          className={`${styles.pageWrapper} row flex-column-reverse flex-md-row mb-4 mb-md-0`}
        >
          <div className="col-md-8 col-lg-8 col-xl-9 pe-2">
            <div className={`${styles.alertsWrpper}`}>
              <p className={`${styles.topText}`}>
                Please Enter the Required Details to Continue..
              </p>
              <div
                className={`${styles.AddLocationWrapper} d-block d-sm-flex align-items-center justify-content-between`}
              >
                <div className={`${styles.inputWrapper} mt-3`}>
                  <GooglePlacesAutoComplete setLat={setLat} setLng={setLng} />
                </div>
                <div className="mt-3">
                  <CustomButton
                    label="At Home"
                    handleClick={handlePickLocation}
                    additionalClass={styles.customBtn}
                  />
                </div>
              </div>
              <div className={`${styles.mainWrapper} mt-4`}>
                <div>
                  <GoogleMaps lat={lat} lng={lng} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3">
            <RightSideBar
              progressBar
              currentStep={2}
              value="162.85"
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />
          </div>
        </div>
        <div className="d-block d-sm-flex align-items-center justify-content-between pb-2 pt-4 pe-0 pe-sm-4">
          <CustomButton
            label="Back"
            handleClick={() => navigateTo("/appointments")}
            additionalClass={styles.BtnDraft}
            variant="darkGray"
          />
          <div className="d-block d-sm-flex align-items-center justify-content-end column-gap-2 w-100 w-sm-75">
            <CustomButton
              label="Next"
              handleClick={() => navigateTo("/appointment-location")}
              additionalClass={styles.BtnNext}
              variant="primary"
            />
          </div>
        </div>
      </div>

      {/* <div>PaymentHistory</div> */}
    </div>
  );
};

export default AddAddress;
