import CustomButton from "components/Common/CustomButton/CustomButton";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import Tabs from "components/Common/Tabs/Tabs";
import { useRef } from "react";
import styles from "style/UpdateInformation.module.css";
import { IMAGES } from "utils/helper/Images";
import BillingInfo from "./BillingInfo";
import InsuranceInfo from "./InsuranceInfo";
import PasswordAndSecurity from "./PasswordAndSecurity";
import PersonalInfo from "./PersonalInfo";

const UpdateInformation = () => {
  const personalRef = useRef();
  const insuranceRef = useRef();
  const billingRef = useRef();
  // const PasswordAndSecurityRef = useRef();

  const handleSaveChanges = () => {
    if (personalRef.current) {
      personalRef.current.handlePersonalInfoSubmit();
    }
    if (insuranceRef.current) {
      insuranceRef.current.handleInsuranceInfoSubmit();
    }
    if (billingRef.current) {
      billingRef.current.handleBillingInfoSubmit();
    }
    // PasswordAndSecurityRef.current.handlePasswordAndSecuritySubmit();
  };

  const tabsData = [
    {
      id: 1,
      text: "Personal Info",
      content: <PersonalInfo ref={personalRef} />,
    },
    {
      id: 2,
      text: "Insurance Info",
      content: <InsuranceInfo ref={insuranceRef} />,
    },
    {
      id: 3,
      text: "Billing Info",
      content: <BillingInfo ref={billingRef} />,
    },
    {
      id: 4,
      text: "Password & Security",
      // content: <PasswordAndSecurity ref={PasswordAndSecurityRef} />,
      content: <PasswordAndSecurity />,
    },
  ];

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`${styles.rightmainWrapper} `}>
        <Navbar
          navlinkText="Update Info"
          navlinkText1="Personal Info"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />
        <div
          className={`row flex-column-reverse flex-md-row mb-4 mb-md-0 ${styles.pageWrapper}`}
        >
          <div className="col-md-8 col-lg-8 col-xl-9">
            <Tabs tabsData={tabsData} />
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3">
            <RightSideBar
              lightGray="lightGray"
              value="162.85"
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pb-2 pe-0 pe-sm-4">
          <CustomButton
            label="Save Changes"
            handleClick={() => handleSaveChanges()}
            additionalClass={styles.BtnDraft}
            variant="secondary"
            rounded
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateInformation;
