import {
  autoSignIn,
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from "@aws-amplify/auth";
import { API_METHODS, callAmplifyAPI } from "api/defaultApi";
import { generateClient } from "aws-amplify/api";
import { createEmployee } from "graphql/mutations";
import * as queries from "graphql/queries";
// import { graphqlOperation } from "aws-amplify";
const client = generateClient();
const signUpApi = async (data) => {
  const payloadData = data.payload;
  try {
    const attributePayload = {
      preferred_username: payloadData.phone,
      email: payloadData.email,
      "custom:role": "Employees",
      "custom:autoConfirm": "true",
      "custom:firstName": `${payloadData.firstName}`,
      "custom:lastName": `${payloadData.lastName}`,
      "custom:note": `${payloadData.firstName} ${payloadData.lastName}`,
      // "custom:labID": obj.id,
    };
    console.log("attribite =>", attributePayload);
    await signOut();
    const signUpRes = await signUp({
      username: payloadData.phone,
      password: payloadData.password,
      options: {
        userAttributes: attributePayload,
        autoSignIn: true,
      },
    });
    const signInres = await autoSignIn();
    const currentUser = await getCurrentUser();
    const newPatient = {
      first: payloadData.firstName,
      last: payloadData.lastName,
      dob: payloadData.dob,
      phone_number: payloadData.phone,
      email: payloadData.email, // Add email attribute
      subID: currentUser.userId,
    };
    console.log("new patient", newPatient);
    const res = await client.graphql({
      query: createEmployee,
      variables: { input: newPatient },
    });
    return res.data.createEmployee;
  } catch (err) {
    console.log("createEmployeeGQL Error:", err);
    return err; // Optionally, rethrow the error to handle it in the calling code
  }
};

const loginApi = async (params) => {
  const payloadData = params.payload;
  try {
    await signOut();
    const loginresponse = await signIn({
      username: payloadData.phone,
      password: payloadData.password,
    });
    console.log("login response", loginresponse);
    const token = await fetchAuthSession();
    console.log("new patient", token);
    const currentUser = await getCurrentUser();
    console.log("current user", currentUser);
    let allItems = [];
    let nextToken = null;
    let iterations = 0;
    do {
      const graphqlData = await client.graphql({
        query: queries.listEmployees,
        variables: {
          filter: {
            subID: { eq: currentUser.userId },
          },
          nextToken: nextToken,
        },
      });
      const { listEmployees } = graphqlData.data;
      const { items, nextToken: newNextToken } = listEmployees;
      allItems = allItems.concat(items);
      nextToken = newNextToken;
      iterations++;
      // Add a safeguard to prevent potential infinite loops
    } while (nextToken && allItems.length === 0);
    console.log("All items:", allItems[0]);
    return allItems;
  } catch (err) {
    console.error("Error logging in:", err.message);
    return err.message;
  }
};
const getTestByEmployeeAPI = async (params) => {
  const models = await callAmplifyAPI(
    "/getTestFromPG",
    params,
    API_METHODS.post
  );
  return models;
};

const getTestRecordByID = async (params) => {
  const models = await callAmplifyAPI(
    "/getRecordById",
    params,
    API_METHODS.post
  );
  return models;
};

// this is the way to call graphql quiries
// const getTestDetailsByID = async (id) => {
//   const res = await client.graphql({
//     query: queries.getTest,
//     variables: { id },
//   });
//   console.log("ress", res.data);
//   return res.data.getTest;
// };

const forgotpasswordApi = async (params) => {
  const payloadData = params;
  try {
    // Reset the password
    const response = await resetPassword({ username: payloadData.phone }); // Pass the username/email of the user
    console.log("response", response);
    // If successful, return a success message or handle it as needed
    return "Password reset otp sent successfully";
  } catch (err) {
    console.error("Error resetting password:", err.message);
    return err.message;
  }
};

const changepasswordApi = async (params) => {
  const payloadData = params;
  try {
    // Reset the password
    const response = await confirmResetPassword({
      username: payloadData.phone,
      confirmationCode: payloadData.otp,
      newPassword: payloadData.password,
    }); // Pass the username/email of the user
    console.log("response", response);
    // If successful, return a success message or handle it as needed
    return "Password reset successful";
  } catch (err) {
    console.error("Error resetting password:", err.message);
    return err.message;
  }
};

export default {
  forgotpasswordApi,
  changepasswordApi,
  signUpApi,
  loginApi,
  getTestByEmployeeAPI,
  getTestRecordByID,
};
