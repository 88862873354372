import { IMAGES } from "./Images";

export const patientRoutes = {
  patient: `/`,
  welcome: `/welcome`,
  notification: `/notification`,
  appointments: `/appointments`,
  paymentHistory: `/payment-history`,
  updateInformation: `/update-information`,
  changePassword: `/change-password`,
  forgotPassword: `/forgot-password`,
  newAccountInfo: `/new-account-info`,
  patientLogin: `/patient-login`,
  paymentHistory: `/payment-history`,
  addAddress: `/add-address`,
  reviewAppointment: `/review-appointment`,
  appointmentLocation: `/appointment-location`,
  personalInsuranceDetails: `/personal-insurance-details`,
  paymentDetails: `/payment-details`,
  billingInfo: `/billing-info`,
  passwordSecurity: `/password-security`,
  InsuranceInfo: `/Insurance-info`,
  paymentInfo: `/payment-info`,
  billingFaqs: `/billing-faqs`,
  viewResult: `/results`,
};
export const item = [
  {
    name: "My Notifications",
    img: IMAGES.notificationIcon,
    link: patientRoutes.notification,
  },
  {
    name: "Appointments",
    img: IMAGES.appointmentsIcon,
    link: patientRoutes.appointments,
  },
  {
    name: "Payment History",
    img: IMAGES.paymentHistoryIcon,
    link: patientRoutes.paymentHistory,
  },
  {
    name: "Update Information",
    img: IMAGES.updateInfoIcon,
    link: patientRoutes.updateInformation,
  },
  {
    name: "View Result",
    img: IMAGES.statusEye,
    link: patientRoutes.viewResult,
  },
];

export const relatedSidebarRoutes = new Map([
  ...item.map((path) => [path.link, path.link]),
  [patientRoutes.addAddress, patientRoutes.appointments],
  [patientRoutes.reviewAppointment, patientRoutes.appointments],
  [patientRoutes.appointmentLocation, patientRoutes.appointments],
  [patientRoutes.personalInsuranceDetails, patientRoutes.appointments],
]);

export const GENDER_TYPES_OPT = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const INSURANCE_PROVIDER_TYPES = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const TABLE_COLUMN_TYPES = {
  shortDate: "shortDate",
  dateTime: "dateTime",
  text: "text",
  upperCaseText: "upperCaseText",
  email: "email",
  phone: "phone",
  currency: "currency",
  image: "image",
  // insuranceProvider: "insuranceProvider",
  number: "number",
  percentage: "percentage",
  labelID: "labelID",
  link: "link",
};

export const TABLE_QUICK_TOOLS = {
  checkbox: "checkbox",
  edit: "edit",
  notes: "notes",
  delete: "delete",
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
