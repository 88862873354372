// passwordAndSecurity component
import CustomButton from "../../../components/Common/CustomButton/CustomButton";
import InputFields from "../../../components/Common/Inputfileds/InputFields";
import { Controller, useForm } from "react-hook-form";
import styles from "../../../style/UpdateInformation.module.css";

const PasswordAndSecurity = () => {
  const { control, handleSubmit, formState, getValues } = useForm({
    mode: "onChange",
  });
  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    console.log(data); // Handle form submission here
  };

  return (
    <div className={`${styles.InsuranceInfoWrapper} w-100`}>
      <div className={`${styles.pageTitle}`}>
        <span>Change Password</span>
      </div>
      <p className={`${styles.Subtitle}`}>
        To change your password, please fill in the field below. Your password
        must contain at least 8 characters, it must also include at least one
        upper case letter, one lower case letter, one number and one special
        character
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.passwordFormFields}`}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <Controller
                name="currentPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "Current password is required",
                }}
                render={({ field }) => (
                  <>
                    <InputFields
                      label="Current Password"
                      type="password"
                      placeholder="Current Password"
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      required
                      roundedSm
                      password
                      shadowSm
                    />
                    {errors.currentPassword && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.currentPassword.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-12 mb-3">
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "New password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                  },
                }}
                render={({ field }) => (
                  <>
                    <InputFields
                      label="New Password"
                      type="password"
                      placeholder="New Password"
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      required
                      roundedSm
                      password
                      shadowSm
                    />
                    {errors.newPassword && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.newPassword.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-12">
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "Confirm password is required",
                  validate: {
                    matchesNewPassword: (value) =>
                      value === getValues("newPassword") ||
                      "Passwords do not match",
                  },
                }}
                render={({ field }) => (
                  <>
                    <InputFields
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm Password"
                      value={field.value}
                      handleChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      required
                      roundedSm
                      password
                      shadowSm
                    />
                    {errors.confirmPassword && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.confirmPassword.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-12 text-center mt-4">
              <CustomButton
                variant="primary"
                additionalClass={`btn ${styles.changePassword}`}
                label="Change Password"
                type="submit"
                disabled={!formState.isValid}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordAndSecurity;
