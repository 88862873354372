// import Axios from "./axios";
// import urls from "./url";
import axios from "axios";

// user api
export const userApi = async ({ data }) => {
  // const token = localStorage.getItem("token");
  try {
    return await axios.get("https://jsonplaceholder.typicode.com/todos/1", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    return err;
  }
};
