import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({ showModal, handleClose, children, title, size }) => {
  console.log("showModal in Modal", showModal);
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size={size}
      className="patient-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
