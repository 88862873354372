import React, { useState } from "react";
import styles from "../../../style/Tabs.module.css";

const Tabs = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(tabsData[0]?.id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  if (!tabsData || tabsData.length === 0) {
    return <div>No tabs data available.</div>;
  }
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsBar}>
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.tabItem} ${
              activeTab === tab.id ? styles.activeTabItem : ""
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.text}
          </div>
        ))}
      </div>

      <div className={styles.tabContent}>
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            style={{ display: activeTab === tab.id ? "block" : "none" }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
