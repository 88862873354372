import CustomButton from "components/Common/CustomButton/CustomButton";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import { IMAGES } from "utils/helper/Images";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { patientRoutes } from "utils/helper/Constants";
import styles from "style/ReviewAppointment.module.css";

const ReviewAppointment = () => {
  const history = useNavigate();
  const navigateTo = (route) => {
    history.push(route);
  };

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`${styles.rightmainWrapper} w-100`}>
        <Navbar
          color
          navlinkText="Appointment"
          navlinkText1="New Appointment"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />

        <div
          className={`${styles.pageWrapper} row flex-column-reverse flex-md-row mb-4 mb-md-0`}
        >
          <div className="col-md-8 col-lg-8 col-xl-9 pe-2">
            <div className={`${styles.alertsWrpper}`}>
              <p className={`${styles.topText}`}>Review Appointment Details</p>
              <div className={`${styles.AddressWrapper} `}>
                <div
                  className={`${styles.topBar} d-flex align-items-center justify-content-between py-2`}
                >
                  <p className={`${styles.subText} mb-0`}>Address</p>
                  <span className="custom-pointer mb-0">
                    <FiEdit className={`${styles.EditIcon} `} />
                  </span>
                </div>
                <div className={`${styles.sprtr} `}></div>
                <div className={`${styles.bottomBar} py-2`}>
                  <p className={`${styles.SmallText}`}>2119 Gelenrose Ave</p>
                  <p className={`${styles.SmallText}`}>
                    2Altadea, CA, 9887, USA
                  </p>
                </div>
              </div>
              <div className={`${styles.AddressWrapper} mt-2`}>
                <div
                  className={`${styles.topBar} d-flex align-items-center justify-content-between py-2`}
                >
                  <p className={`${styles.subText} mb-0`}>
                    Reason for Visiting
                  </p>
                  <span className="custom-pointer mb-0">
                    <FiEdit className={`${styles.EditIcon} `} />
                  </span>
                </div>
                <div className={`${styles.sprtr} `}></div>
                <div className={`${styles.bottomBar} py-2`}>
                  <p className={`${styles.VisistinText} `}>
                    <img src={IMAGES.ideaIcon} alt="i" className="me-2" />
                    Employer Drug and Alcohol
                  </p>
                  <div className={`${styles.reasonWrapper} `}>
                    <div className={`${styles.selectedBox}`}>
                      {" "}
                      <img
                        src={IMAGES.ideaIcon}
                        alt="i"
                        className="me-2"
                      />{" "}
                      <p className={`${styles.SmallText}`}>
                        Urine - Feredally mandated
                      </p>{" "}
                    </div>
                    <div className={`${styles.selectedBox}`}>
                      {" "}
                      <img
                        src={IMAGES.ideaIcon}
                        alt="i"
                        className="me-2"
                      />{" "}
                      <p className={`${styles.SmallText}`}>Breathe Alcohol</p>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.AddressWrapper} mt-2 mb-2`}>
                <div
                  className={`${styles.topBar} d-flex align-items-center justify-content-between py-2`}
                >
                  <p className={`${styles.subText} mb-0`}>
                    Location, Date, Time
                  </p>
                  <span className="custom-pointer mb-0">
                    <FiEdit className={`${styles.EditIcon} `} />
                  </span>
                </div>
                <div className={`${styles.sprtr} `}></div>
                <div className={`${styles.DateWrapper} py-2`}>
                  <p className={`${styles.DateTopText}`}>
                    Quest Diagnostice - Agoura Hills Canwoo - Employer Drug
                    Testing Not Offered
                  </p>
                  <div
                    className={`${styles.LocationDateContent} row pt-3 align-items-center`}
                  >
                    <div className="col-md-4">
                      <div className={`${styles.leftContent} `}>
                        <p
                          className={`${styles.SmallText} ${styles.LineHeight20} ${styles.TextColor}`}
                        >
                          2039 Glenrose Ave
                        </p>
                        <p
                          className={`${styles.SmallText} ${styles.LineHeight20} ${styles.TextColor}`}
                        >
                          Ste 101
                        </p>
                        <p
                          className={`${styles.SmallText} ${styles.LineHeight20} ${styles.TextColor}`}
                        >
                          Altadena, CA 91301-9876
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className={`${styles.rightContent} `}>
                        <p
                          className={`${styles.SmallText} ${styles.LineHeight20}`}
                        >
                          October26,2023
                        </p>
                        <p
                          className={`${styles.SmallText} ${styles.LineHeight20}`}
                        >
                          10 to 11AM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.AddressWrapper} mt-2 mb-2`}>
                <div
                  className={`${styles.topBar} d-flex align-items-center justify-content-between py-2`}
                >
                  <p className={`${styles.subText} mb-0`}>
                    Personal and Insurance Details
                  </p>
                  <span className="custom-pointer mb-0">
                    <FiEdit className={`${styles.EditIcon} `} />
                  </span>
                </div>
                <div className={`${styles.sprtr} `}></div>
                <div className={`${styles.DateWrapper} py-2`}>
                  <div
                    className={`${styles.LocationDateContent} row align-items-center`}
                  >
                    <div className="col-md-4">
                      <div className={`${styles.leftContent} `}>
                        <p className={`${styles.DateTopText}`}>Personal Info</p>
                        <p
                          className={`${styles.SmallText} ${styles.TextColor}`}
                        >
                          carmen martiz
                        </p>
                        <p
                          className={`${styles.SmallText} ${styles.TextColor} ${styles.textBreak}`}
                        >
                          c.carmen9990@gmail.com
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className={`${styles.rightContent} `}>
                        <p className={`${styles.DateTopText}`}>
                          Insurance Info
                        </p>
                        <div
                          className={`${styles.PerspnalInfocontent} d-flex align-items-start align-items-sm-center gap-1 gap-sm-4`}
                        >
                          <div className={`${styles.InfoLeftcon}`}>
                            <p
                              className={`${styles.SmallText} ${styles.TextColor}`}
                            >
                              October26,2023
                            </p>
                            <p
                              className={`${styles.SmallText} ${styles.TextColor}`}
                            >
                              10 to 11AM
                            </p>
                          </div>
                          <div className={`${styles.InfoRightcon} `}>
                            <p
                              className={`${styles.SmallText} ${styles.TextColor}`}
                            >
                              October26,2023
                            </p>
                            <p
                              className={`${styles.SmallText} ${styles.TextColor}`}
                            >
                              10 to 11AM
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-3">
            <RightSideBar
              progressBar
              currentStep={4}
              value="162.85"
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />

            <div className="d-md-block d-none">
              <CustomButton
                label="Schedule Appointment"
                handleClick={() => navigateTo(patientRoutes.addAddress)}
                additionalClass={styles.BtnNext}
                varinat="primary"
              />
            </div>
          </div>
        </div>
        <div className="d-md-none d-flex align-items-center justify-content-end pb-2 pe-0 pe-sm-4">
          <CustomButton
            label="Schedule Appointment"
            handleClick={() => navigateTo(patientRoutes.paymentHistory)}
            additionalClass={styles.BtnNext}
            varinat="primary"
          />
        </div>
      </div>

      {/* <div>PaymentHistory</div> */}
    </div>
  );
};

export default ReviewAppointment;
