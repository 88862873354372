import PatientApi from "api/PatientApi";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/authDuck";
import { signOut } from "@aws-amplify/auth";

// user saga
export function* signUpSaga(payload) {
  console.log("saga paload", payload);
  try {
    const response = yield call(PatientApi.signUpApi, payload);
    console.log("response", response);
    if (response) {
      console.log("response data", response);
      yield put(actions.signUpSuccess({ response: response }));
    }
  } catch (error) {
    console.log("error", error);
  } finally {
  }
}

export function* loginSaga(payload) {
  try {
    // Call the login API function from your PatientApi module
    const response = yield call(PatientApi.loginApi, payload);
    
    if (response) {
      // Dispatch an action to handle the successful login
      yield put(actions.loginSuccess(response));
    } else {
      // Dispatch an action to handle login failure
      yield put(actions.loginFailure("Login failed. Please try again."));
    }
  } catch (error) {
    // Dispatch an action to handle login failure due to an error
    yield put(actions.loginFailure(error.message));
  }
}

export function* logoutSaga() {
  try {
    signOut();
    // Dispatch an action to clear the user state upon logout
    yield put(actions.logoutSuccess());
  } catch (error) {
    // Dispatch an action to handle logout failure
    yield put(actions.logoutFailure(error.message));
  }
}

export function* watchAuthSagas() {
  yield takeLatest(actions.signUpRequest.type, signUpSaga);
  yield takeLatest(actions.loginRequest.type, loginSaga);
  yield takeLatest(actions.logoutRequest.type, logoutSaga);
}
