import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";

export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str
    .toLowerCase()
    .replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const CapsFirstLetter = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

export const calculateTdWidth = (width, numberOfCol) =>
  // eslint-disable-next-line
  screen.width >= "768" && screen.width <= "1024"
    ? 100
    : Math.ceil(width / numberOfCol);

export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const convertToUpper = (val) => {
  const v = getValidSpaces(val);
  if (val) return v.toUpperCase();
  return val;
};

export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch (err) {}

    if (!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return {
        ...obj,
        isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1,
        width: f.width,
      };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if (!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter(
      (obj) => !lsData.includes(obj.id)
    );
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const formatDateOfBirth = (dob) =>
  dob
    ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}`
    : "";

export const formatDate = (date) =>
  date ? moment(date).format("MMM DD, YYYY") : "";

export const formatDateTime = (date) =>
  date ? moment(date).format("MMM DD, YYYY - hh:mm A") : "";

export const formatDateMDY = (date) =>
  date ? moment(date).format("MM/DD/YYYY") : "";

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  if (phoneNumber) {
    return formatPhoneNumberIntl(phoneNumber)?.replace(/\s/g, "");
  }
  return phoneNumber;
};

export const tdEmail = (email) => (
  <td
    key={email}
    className=" ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const tdPhone = (phoneNumber, textAlign) => (
  <td
    key={phoneNumber}
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
      textAlign: textAlign || "",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() =>
      phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)
    }
    title={formatPhoneNumber(phoneNumber)}
  >
    {formatPhoneNumber(phoneNumber)}
  </td>
);

export const formatLabelID = (id) => {
  if (!id) return "";
  const splitId = id.split("-");
  return splitId[splitId.length - 1];
};

export const formatCurrency = (val) => {
  if (!val) return "$0.00";
  const obj = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return obj.format(val);
};

export const formatNumber = (num) => {
  if (!num) return 0;
  return num.toLocaleString("en-US");
};

export const formatPercentage = (val) => {
  if (!val) return "0%";

  // Multiply the value by 100 to convert it to a percentage and round it to two decimal places.
  // const percentageValue = (val * 100).toFixed(2);
  const percentageValue =
    Math.abs(val) < 1 ? (val * 100).toFixed(2) : val.toFixed(2);
  const obj = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return obj.format(percentageValue / 100);
};
