import { useState } from "react";
import SortIcon from "./SortIcon";
// import timesIcon from "assets/img/times-circle.svg";

const HeaderItem = (props) => {
  const {
    ItemKey,
    title,
    flipSort,
    aligned,
    sortBy,
    fieldEditableOnDoubleClick,
    value,
    onChange,
    onCancel,
    sortDescending,
    cssClass,
    customHeadForm,
    minWidth,
    width,
    draggable,
    innerDivWidth,
    handleDrop,
    handleDragStart,
  } = props;
  const [editable, setEditable] = useState(false);
  const handleSaveChange = () => {
    setEditable(false);
  };

  return (
    <th
      className={`border-0 ${aligned ?? ""} ${cssClass ?? ""} ${
        customHeadForm ?? ""
      }`}
      style={{
        color: sortBy === ItemKey ? "#A72632" : "#231f20",
        fontWeight: "bold",
        maxWidth: width,
        minWidth: minWidth,
        width: width,
      }}
      onDoubleClick={() => fieldEditableOnDoubleClick && setEditable(true)}
      draggable={draggable}
      onDragStart={(e) => draggable && handleDragStart(e)}
      onDragOver={(e) => draggable && e.preventDefault()}
      onDrop={(e) => draggable && handleDrop(e)}
    >
      <div
        className={`${aligned} ${
          fieldEditableOnDoubleClick && "position-relative"
        }`}
        style={{
          width: innerDivWidth,
        }}
      >
        {editable ? (
          <input
            className="custom-input"
            type="text"
            value={value}
            onChange={(e) => onChange && onChange(e)}
            onBlur={handleSaveChange}
            placeholder=""
            autoFocus
          />
        ) : (
          <>
            <span title={title}>{title}</span>
            {value && (
              <span
                className="remove-icon remove-icon-custom"
                onClick={(e) => onCancel && onCancel("")}
              >
                {/* <img style={{ width: "15px" }} src={timesIcon} alt="" /> */}
              </span>
            )}
          </>
        )}
        {flipSort && (
          <SortIcon
            sortName={ItemKey}
            sortBy={sortBy}
            sortDescending={sortDescending}
            handleClick={() => flipSort(ItemKey)}
          />
        )}
      </div>
    </th>
  );
};

export default HeaderItem;
