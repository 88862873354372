import React from "react";
// import Header from "./header";
import Sidebar from "./sidebar";

const PanelLayout = ({ children }) => {
  return (
    <div className="">
      {/* <Header /> */}
      <div className="d-flex">
        <Sidebar />
        {children}
      </div>
    </div>
  );
};

export default PanelLayout;
