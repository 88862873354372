import React, { useState } from "react";
import Slider from "react-slick";

import styles from "style/DateCalenderSlider.module.css";
import "slick-carousel/slick/slick.css";
import "style/slick-theme.css";
import { IMAGES } from "utils/helper/Images";

const weekdays = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

const DateCalenderSlider = ({ slideToShow }) => {
  console.log("slideToShow", slideToShow);
  const currentDate = new Date(); // Get the current date
  const dates = [];

  // Generate 14 dates from the current date
  for (let i = 0; i < 14; i++) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + i);
    dates.push(date);
  }

  const [activeSlide, setActiveSlide] = useState(0);

  const CustomPrevArrow = (props) => (
    <div {...props} className={`${styles.customArrow} ${styles.prevArrow}`}>
      <img src={IMAGES.LeftArrowSlider} alt="icon" />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className={`${styles.customArrow} ${styles.nextArrow}`}>
      <img src={IMAGES.RightArrowSlider} alt="icon" />
    </div>
  );

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={`${styles.DateSliderWrapper}`}>
      <div className={`${styles.AvailDays}`}>
        <p className={`${styles.AvailText}`}>Days Available</p>
      </div>

      <div className={`${styles.sliderContainer} patient-slider`}>
        <Slider {...settings}>
          {dates.map((date, index) => (
            <div
              key={index}
              className={`${styles.dateSlide} ${
                index === activeSlide ? styles.activeSlide : ""
              }`}
            >
              <div className={`${styles.dateItem} date-items-slide`}>
                <div className={`${styles.date} date-slide`}>
                  {date.getDate()}
                </div>
                <div className={`${styles.weekday} weekday-slide`}>
                  {weekdays[date.getDay()]}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DateCalenderSlider;
