import styles from "style/SearchPanel.module.css";
import { IMAGES } from "utils/helper/Images";
import InputFields from "../Inputfileds/InputFields";
import SearchBar from "../Search/Search";

const SearchPanel = (props) => {
  const { pageName } = props;

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    // Handle date change as needed
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    // Handle search text change as needed
  };

  const handleSelectFromCalendar = () => {
    // Handle the action for selecting from the calendar
  };

  return (
    <div className={`${styles.mainTopBar}`}>
      {/* Left side of the bar */}
      <div className={`${styles.leftContent}`}>
        <div className={`${styles.pageName}`}>{pageName}</div>
      </div>

      {/* Right side of the bar */}
      <div
        className={`d-flex align-items-center text-white ${styles.rightContent}`}
      >
        {/* Select Date Menu */}
        {/* <Calendar onChange={handleDateChange} onSelectFromCalendar={handleSelectFromCalendar} /> */}
        <div className={`${styles.Datepicker} patient-dasbar-datepicker`}>
          <InputFields
            type="datePicker"
            inputStyle={styles.inputLogin}
            labelStyle={styles.labelFileds}
            cssClass="patientDatePicker"
            customClass={`w-100 ${styles.historyInputDatePicker}`}
            // label="Patient or Guarantor Date of Birth"
            // value={
            //   newUser.dob && new Date(newUser.dob) != "Invalid Date"
            //     ? moment(newUser.dob, "YYYY-MM-DD").toDate()
            //     : null
            // }
            index="dob"
            placeholder="10/23/1985"
            // disabled={editButton}
            handleChange={
              (e) => console.log(e)
              // setNewUser({
              //   ...newUser,
              //   dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
              // })
            }
            // minDate={new Date("1900-01-01")}
            // maxDate={new Date()}
            // required
          />
        </div>
        {/* Search Bar */}
        <SearchBar
          onChange={handleSearchChange}
          customClass={styles.searchbarWidth}
        />

        {/* Filter Icon */}
        <div className={`${styles.iconWrapper}`}>
          <img src={IMAGES.filterIcon} alt="filter-icon" />
        </div>

        {/* Info Icon */}
        <div className={`${styles.iconWrapper}`}>
          <img src={IMAGES.infoIcon} alt="info-icon" />
        </div>
        {/* Print Icon */}
        <div className={`${styles.iconWrapper}`}>
          <img src={IMAGES.printIcon} alt="print-icon" />
        </div>
      </div>
    </div>
  );
};

export default SearchPanel;
