import { IMAGES } from "utils/helper/Images";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link } from "react-router-dom";
import CustomRadioButton from "components/Common/CustomRadioButton/CustomRadioButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import styles from "style/UpdateInformation.module.css";
import { Form } from "react-bootstrap";

const InsuranceInfo = forwardRef((props, ref) => {
  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);
  const [accordion3Open, setAccordion3Open] = useState(false);
  const [uploadedFrontImage, setUploadedFrontImage] = useState(null);
  const [uploadedBackImage, setUploadedBackImage] = useState(null);
  const [isPrimaryInsuranceEnabled, setIsPrimaryInsuranceEnabled] =
    useState(false);

  const toggleAccordion1 = () => {
    setAccordion1Open(!accordion1Open);
    setAccordion2Open(false);
    setAccordion3Open(false);
  };
  const toggleAccordion2 = () => {
    setAccordion2Open(!accordion2Open);
    setAccordion1Open(false);
    setAccordion3Open(false);
  };
  const toggleAccordion3 = () => {
    setAccordion3Open(!accordion3Open);
    setAccordion1Open(false);
    setAccordion2Open(false);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleChangeRadio = (e) => {
    console.log("eeee", e);
  };

  const handleChangeImage = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (imageType === "front") {
          setUploadedFrontImage(reader.result);
        } else if (imageType === "back") {
          setUploadedBackImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInsuranceSubmit = (data) => {
    console.log("insuranceData", data);
  };

  useImperativeHandle(ref, () => ({
    handleInsuranceInfoSubmit() {
      handleSubmit(handleInsuranceSubmit)();
      // Any other logic you want to execute
    },
  }));

  const handleSwitchChange = () => {
    setIsPrimaryInsuranceEnabled(!isPrimaryInsuranceEnabled);
  };

  return (
    <div className={`${styles.InsuranceInfoWrapper} w-100`}>
      <form onSubmit={handleSubmit(handleInsuranceSubmit)}>
        <div className={`row`}>
          <div className="col-xl-5 col-lg-12">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className={styles.insuranceListTitle}>Insurance List</div>
              <img
                src={IMAGES.addicon}
                alt="Additional Icon"
                className={styles.additionalIcon}
              />
            </div>
            <div className={styles.insuranceListContainer}>
              <div className={styles.accordionContainer}>
                <div
                  className={`${styles.accordionTitle} ${
                    !accordion1Open ? styles.titleBgColor : ""
                  }`}
                  onClick={toggleAccordion1}
                >
                  Primary Insurance
                  <span className={styles.icon}>
                    {accordion1Open ? (
                      <img src={IMAGES.upArrowIcon} alt="up arrow" />
                    ) : (
                      <img src={IMAGES.downArrowIcon} alt="down arrow" />
                    )}
                  </span>
                </div>
                {accordion1Open && (
                  <div className={`${styles.accordionContent}`}>
                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.insuranceIcon}>
                        <img src={IMAGES.companyicon} alt="Additional Icon" />
                      </div>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>
                          Primary Insurance
                        </div>
                        <div className={styles.detail}>MEDCARE PLAN</div>
                      </div>
                      <div className={styles.switch}>
                        {/* <Switch
                          onChange={() =>
                            setIsPrimaryInsuranceEnabled(
                              !isPrimaryInsuranceEnabled
                            )
                          }
                          checked={isPrimaryInsuranceEnabled}
                          onColor="#5890FF"
                          onHandleColor="ffffff"
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        /> */}
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Primary Insurance"
                          checked={isPrimaryInsuranceEnabled}
                          onChange={handleSwitchChange}
                        />
                        <span>Active</span>
                      </div>
                    </div>

                    <div className={styles.separator}></div>

                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.expires}>
                        <div className={`${styles.smallTitle} text-uppercase`}>
                          Insurance No
                        </div>
                        <div className={styles.detail}>xee1234566534</div>
                      </div>
                      <div className={styles.expires}>
                        <div className={`${styles.smallTitle} text-uppercase`}>
                          Expires
                        </div>
                        <div className={styles.detail}>09/15</div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`${styles.accordionTitle} ${
                    !accordion2Open ? styles.titleBgColor : ""
                  }`}
                  onClick={toggleAccordion2}
                >
                  Secondary Insurance
                  <span className={styles.icon}>
                    {accordion2Open ? (
                      <img src={IMAGES.upArrowIcon} alt="up arrow" />
                    ) : (
                      <img src={IMAGES.downArrowIcon} alt="down arrow" />
                    )}
                  </span>
                </div>
                {accordion2Open && (
                  <div className={`${styles.accordionContent}`}>
                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.insuranceIcon}>
                        <img src={IMAGES.companyicon} alt="Additional Icon" />
                      </div>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>
                          Primary Insurance
                        </div>
                        <div className={styles.detail}>MEDCARE PLAN</div>
                      </div>
                      <div className={styles.switch}>
                      <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Primary Insurance"
                          checked={isPrimaryInsuranceEnabled}
                          onChange={handleSwitchChange}
                        />
                        <span>Active</span>
                        {/* <Switch
                          onChange={() =>
                            setIsPrimaryInsuranceEnabled(
                              !isPrimaryInsuranceEnabled
                            )
                          }
                          checked={isPrimaryInsuranceEnabled}
                          onColor="#5890FF"
                          onHandleColor="ffffff"
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        /> */}
                        
                      </div>
                    </div>

                    <div className={styles.separator}></div>

                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>Insurance No</div>
                        <div className={styles.detail}>xee1234566534</div>
                      </div>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>Expires</div>
                        <div className={styles.detail}>09/15</div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`${styles.accordionTitle} ${
                    !accordion3Open ? styles.titleBgColor : ""
                  }`}
                  onClick={toggleAccordion3}
                >
                  Tertiary Insurance
                  <span className={styles.icon}>
                    {accordion3Open ? (
                      <img src={IMAGES.upArrowIcon} alt="up arrow" />
                    ) : (
                      <img src={IMAGES.downArrowIcon} alt="down arrow" />
                    )}
                  </span>
                </div>
                {accordion3Open && (
                  <div className={`${styles.accordionContent}`}>
                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.insuranceIcon}>
                        <img src={IMAGES.companyicon} alt="Additional Icon" />
                      </div>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>
                          Primary Insurance
                        </div>
                        <div className={styles.detail}>MEDCARE PLAN</div>
                      </div>
                      <div className={styles.switch}>
                      <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Primary Insurance"
                          checked={isPrimaryInsuranceEnabled}
                          onChange={handleSwitchChange}
                        />
                        <span>Active</span>
                      </div>
                    </div>

                    <div className={styles.separator}></div>

                    <div className={styles.insuranceDetailContainer}>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>Insurance No</div>
                        <div className={styles.detail}>xee1234566534</div>
                      </div>
                      <div className={styles.expires}>
                        <div className={styles.smallTitle}>Expires</div>
                        <div className={styles.detail}>09/15</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-end">
                  <Link to="" className="patient-text-blue">
                    View Older
                  </Link>
                </div>
                <div className="mt-4">
                  <h5>Insurance card</h5>
                  <div className="d-flex justify-content-between gap-2">
                    <div className={styles.cardWrapper}>
                      <div className={styles.cardInner}>
                        <span className="text-uppercase">Card Front Side</span>
                        <img
                          src={uploadedFrontImage || IMAGES.ImgPlaceHolder}
                          alt="front side"
                        />
                      </div>
                      <span
                        className={`position-relative mt-2 d-block text-center  cursor-pointer ${
                          uploadedFrontImage
                            ? "patient-text-blue text-decoration-underline"
                            : "text-danger"
                        }`}
                      >
                        <input
                          type="file"
                          className={styles.uploadImageClicked}
                          onChange={(e) => handleChangeImage(e, "front")}
                        />
                        {uploadedFrontImage ? "Update" : "Upload Image"}
                      </span>
                    </div>
                    <div className={styles.cardWrapper}>
                      <div className={styles.cardInner}>
                        <span className="text-uppercase">Card Back Side</span>
                        <img
                          src={uploadedBackImage || IMAGES.ImgPlaceHolder}
                          alt="front side"
                        />
                      </div>
                      <span
                        className={`cursor-pointer position-relative text-center d-block mt-2 ${
                          uploadedBackImage
                            ? "patient-text-blue text-decoration-underline"
                            : "text-danger"
                        }`}
                      >
                        <input
                          type="file"
                          className={styles.uploadImageClicked}
                          onChange={(e) => handleChangeImage(e, "back")}
                        />
                        {uploadedBackImage ? "Update" : "Upload Image"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-12 mt-4 mt-xl-0">
            <div className={styles.insuranceInfoTitle}>Insurance Info</div>
            <div className="mb-3 mt-3">
              <Controller
                name="insurance"
                control={control}
                defaultValue=""
                rules={{ required: "Insurance is required" }}
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      type="text"
                      inputStyle={styles.inputLogin}
                      labelStyle={styles.labelFileds}
                      value={field.value}
                      label="Insurance"
                      index="insurance"
                      maxLength={100}
                      placeholder="CA Blue She"
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                    />
                    {errors.insurance && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.insurance.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <Controller
                  name="groupId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        roundedSm
                        label="Group ID"
                        type="text"
                        placeholder="Enter Group Id"
                        labelStyle={styles.customlable}
                        groupWrapper={styles.fieldsWrapper}
                        value={field.value}
                        index="groupId"
                        maxLength={100}
                        handleChange={(e) => field.onChange(e.target.value)}
                        CustomIcon={<AiOutlineUsergroupAdd />}
                      />
                      {errors.groupId && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.groupId.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="insuranceNumber"
                  control={control}
                  rules={{ required: "Insurance number is required" }}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <InputFields
                        roundedSm
                        label="Insurance No"
                        type="text"
                        placeholder="IEOGJREIOGJ"
                        labelStyle={styles.customlable}
                        groupWrapper={styles.fieldsWrapper}
                        value={field.value}
                        index="insuranceNumber"
                        maxLength={100}
                        required
                        handleChange={(e) => field.onChange(e.target.value)}
                        CustomIcon={<MdOutlineAccountBalanceWallet />}
                      />
                      {errors.insuranceNumber && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {errors.insuranceNumber.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className={styles.advantagePlanWrapper}>
                <div className="d-block d-md-flex align-items-center justify-content-between">
                  <div className={styles.subquestions}>
                    Is This a Medicare Advantage Plan?
                  </div>
                  <div className="d-flex align-items-center justify-content-evenly">
                    <CustomRadioButton
                      label="Yes"
                      checked
                      name="advantagePlan"
                      id="advantagePlanYes"
                      handleChange={(e) => handleChangeRadio(e)}
                    />
                    <CustomRadioButton
                      label="No"
                      name="advantagePlan"
                      cssClass="ms-5"
                      id="advantagePlanNo"
                      handleChange={(e) => handleChangeRadio(e)}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <Controller
                    name="medicareNo"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <InputFields
                          label="Medicare No"
                          value={field.value}
                          handleChange={(e) => field.onChange(e.target.value)}
                          type="text"
                          roundedSm
                          placeholder="IEOGJREIOGJ"
                          CustomIcon={<MdOutlineAccountBalanceWallet />}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <div className={styles.advantagePlanWrapper}>
                  <div className="d-block d-md-flex  align-items-center justify-content-between">
                    <div className={styles.subquestions}>
                      Is Medicare a Secondary Plan?
                    </div>
                    <div className="d-flex align-items-center justify-content-evenly">
                      <CustomRadioButton
                        label="Yes"
                        name="SecondaryPlan"
                        id="SecondaryPlanYes"
                        handleChange={(e) => handleChangeRadio(e)}
                      />
                      <CustomRadioButton
                        label="No"
                        cssClass="ms-5"
                        name="SecondaryPlan"
                        id="SecondaryPlanNo"
                        checked
                        handleChange={(e) => handleChangeRadio(e)}
                      />
                    </div>
                  </div>
                  <div className="mt-3 patient-dropDown-wrapper">
                    <Controller
                      name="reason"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <div className="mt-3 patient-dropDown-wrapper">
                          <InputFields
                            label="Reason"
                            type="dropDown"
                            value={field.value}
                            handleChange={(e) => field.onChange(e.target.value)}
                            roundedSm
                            placeholder="IEOGJREIOGJ"
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default InsuranceInfo;
