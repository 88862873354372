import { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import {
  MdEditLocationAlt,
  MdOutlineAccountBalanceWallet,
  MdOutlineSecurity,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import InputFields from "../../../components/Common/Inputfileds/InputFields";
// import { updatePatient } from "store/features/patientPortal/patients/patientsAction";
import styles from "style/UpdateInformation.module.css";

const PersonalInfo = forwardRef((props, ref) => {
  // const currentUser = useSelector((state) => state.patients.patient);

  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useForm({ mode: "onChange" });
  const { errors } = formState;

  const handlePersonalSubmit = async (data) => {
    try {
      // await dispatch(updatePatient({ ...data, subId: currentUser.subID }));
    } catch (error) {
      console.log(error);
    }
  };

  useImperativeHandle(ref, () => ({
    handlePersonalInfoSubmit() {
      handleSubmit(handlePersonalSubmit)();
      // Any other logic you want to execute
    },
  }));

  return (
    <div className={`${styles.LoginWrapper}`}>
      <form onSubmit={handleSubmit(handlePersonalSubmit)}>
        <div className="row">
          <div className="col-xl-4 col-lg-6 mb-3 col-md-6 col-sm-6 col-12">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: "First name is required" }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="First Name"
                    type="text"
                    placeholder="Enter First Name"
                    labelStyle={styles.customlable}
                    groupWrapper={styles.fieldsWrapper}
                    value={field.value}
                    index="firstName"
                    maxLength={100}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    required
                    CustomIcon={<FaRegUser />}
                  />
                  {errors.firstName && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.firstName.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="middleName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Middle Name"
                    type="text"
                    placeholder="Enter Middle Name"
                    labelStyle={styles.customlable}
                    groupWrapper={styles.fieldsWrapper}
                    value={field.value}
                    index="middleName"
                    maxLength={100}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    CustomIcon={<FaRegUser />}
                  />
                  {errors.middleName && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.middleName.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: "Last name is required" }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Last Name"
                    type="text"
                    placeholder="Enter Last Name"
                    labelStyle={styles.customlable}
                    groupWrapper={styles.fieldsWrapper}
                    value={field.value}
                    index="lastName"
                    maxLength={100}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    required
                    CustomIcon={<FaRegUser />}
                  />
                  {errors.lastName && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.lastName.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-12 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: "Address is required" }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Address"
                    type="text"
                    placeholder="Enter Your House Address"
                    labelStyle={styles.customlable}
                    groupWrapper={styles.fieldsWrapper}
                    value={field.value}
                    index="address"
                    maxLength={100}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    required
                    CustomIcon={<MdEditLocationAlt />}
                  />
                  {errors.address && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.address.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 patient-phone-input col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone number is required",
                pattern: {
                  value: /^\+\d{1,3}\d{3}\d{3}\d{4}$/,
                  message: "Please enter a valid phone number (+xxxxxxxxxx).",
                },
              }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Phone"
                    type="phone"
                    placeholder="+1 625 999 3488"
                    value={field.value}
                    index="phone"
                    maxLength={65}
                    handleChange={(e) => {
                      field.onChange(e);
                    }}
                    required
                  />
                  {errors.phone && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.phone.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 patient-phone-input col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="faxNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Fax Number"
                    type="text"
                    placeholder="+1 625 999 3488"
                    value={field.value}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                  {errors.faxNumber && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.faxNumber.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter a valid email address",
                },
              }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    label="Email"
                    type="email"
                    placeholder="m.carmen1952@gmail.com"
                    groupWrapper={styles.fieldsWrapper}
                    value={field.value}
                    index="email"
                    maxLength={25}
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    required
                    CustomIcon={<HiOutlineMail />}
                  />
                  {errors.email && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.email.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="socialSecurityNumber"
              control={control}
              defaultValue=""
              rules={{
                required: "Social security number is required",
                pattern: {
                  value: /^\d{3}-\d{2}-\d{4}$/,
                  message:
                    "Please enter a valid Social Security Number (XXXXXXXXX)",
                },
              }}
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    type="text"
                    inputStyle={styles.inputLogin}
                    labelStyle={styles.labelFileds}
                    value={field.value}
                    label="Social Security Numbers"
                    index="socialSecurityNumber"
                    maxLength={11}
                    placeholder="XXX-XX-XXXX"
                    handleChange={(e) => {
                      const value = e.target.value
                        .replace(/\D/g, "")
                        .substring(0, 9);
                      const formattedValue = value.replace(
                        /(\d{3})(\d{2})(\d{4})/,
                        "$1-$2-$3"
                      );
                      field.onChange(formattedValue);
                    }}
                    required
                    CustomIcon={<MdOutlineSecurity />}
                  />
                  {errors.socialSecurityNumber && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.socialSecurityNumber.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="medicalRecordNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    type="number"
                    inputStyle={styles.inputLogin}
                    labelStyle={styles.labelFileds}
                    value={field.value}
                    label="Medical Record Numbers"
                    index="medicalRecordNumber"
                    maxLength={100}
                    placeholder="XX-XXX-XXXX"
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    CustomIcon={<MdOutlineAccountBalanceWallet />}
                  />
                  {errors.medicalRecordNumber && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.medicalRecordNumber.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-xl-4 mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <Controller
              name="accountNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <InputFields
                    roundedSm
                    type="number"
                    inputStyle={styles.inputLogin}
                    labelStyle={styles.labelFileds}
                    value={field.value}
                    label="Account Numbers"
                    index="accountNumber"
                    maxLength={100}
                    placeholder="XX-XXX-XXXX"
                    handleChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    CustomIcon={<MdOutlineAccountBalanceWallet />}
                  />
                  {errors.accountNumber && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {errors.accountNumber.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default PersonalInfo;
