import React from "react";
import styles from "style/CustomBtn.module.css";

const CustomButton = ({
  label,
  additionalClass,
  handleClick,
  variant,
  icon: IconComponent,
  rounded,
  disabled,
  iconStyle,
}) => {
  const getButtonColor = () => {
    switch (variant) {
      case "primary":
        return styles.primaryButton;
      case "secondary":
        return styles.secondaryButton;
      case "success":
        return styles.successButton;
      case "lightGray":
        return styles.lightGrayButton;
      case "darkGray":
        return styles.darkGrayButton;
      default:
        return styles.primaryButton;
    }
  };
  return (
    <button
      disabled={disabled}
      className={`${
        styles.customBtn
      } ${additionalClass} btn d-flex align-items-center justify-content-center ${getButtonColor()} ${
        rounded && styles.rounded
      }`}
      onClick={handleClick}
    >
      {label}
      {IconComponent && <IconComponent className={`${iconStyle} ms-2`} />}
    </button>
  );
};

export default CustomButton;
