import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";

const Loader = ({ cssClass }) => {
  return (
    <div className="loader-wrapper">
      <Bounce
        style={{
          // alignItems: "center",
          // justifyContent: "center",
          zIndex: 20,
          display: "flex",
          flexDirection: "column",
        }}
        size={35}
        className={`${cssClass} loader`}
      />
    </div>
  );
};
export default Loader;
