import { relatedSidebarRoutes, item } from "utils/helper/Constants.jsx";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IMAGES } from "utils/helper/Images.jsx";
import styles from "style/SideBar.module.css";

const SideBar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const handleItemClick = (itemName) => {
    // setActiveItem(itemName);
    setSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div
      className={`${styles.sidebarWrapper} ${
        sidebarOpen ? styles.sidebarOpen : ""
      }`}
    >
      <div className={styles.sidebarToggle} onClick={toggleSidebar}>
        <div className={styles.hamburgerIcon}></div>
        <div className={styles.hamburgerIcon}></div>
        <div className={styles.hamburgerIcon}></div>
      </div>

      <Link to="/welcome" className={styles.sidebarLogoWrapper}>
        <img src={IMAGES.logoFIlled} alt="logo" />
      </Link>

      <ul className={`list-unstyled ${styles.listingWrapper}`}>
        {item.map((i) => (
          <li
            key={i.name}
            onClick={() => handleItemClick(i.name)}
            className={
              relatedSidebarRoutes.get(location.pathname) === i.link
                ? styles.activeClass
                : ""
            }
          >
            <Link to={i.link}>
              <img src={i.img} alt={i.name} /> {i.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
