import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const GoogleMaps = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = useState(null);
  useEffect(() => {
    if (map) {
      const latLng = {
        lat: lat === "" ? center.lat : parseFloat(lat),
        lng: lng === "" ? center.lng : parseFloat(lng),
      };
      const bounds = new window.google.maps.LatLngBounds(latLng);
      map.fitBounds(bounds);

      // Check if map has a truthy value before adding the listener
      if (map.addListener) {
        map.setZoom(12);
        // Add an event listener for zoom_changed
        const zoomListener = map.addListener("zoom_changed", () => {
          //   console.log("Zoom level changed:", map.getZoom());
          // Additional actions after the zoom level has been updated
        });

        // Cleanup the listener when the component unmounts or when the map changes
        return () => {
          window.google.maps.event.removeListener(zoomListener);
        };
      }
    }
  }, [lat, lng, map]);

  const onLoad = useCallback(
    function callback(map) {
      const latLng = {
        lat: lat === "" ? center.lat : parseFloat(lat),
        lng: lng === "" ? center.lng : parseFloat(lng),
      };

      // Check if map has a truthy value before adding the listener
      if (map && map.addListener) {
        // Use addListenerOnce to ensure the listener is triggered only once
        window.google.maps.event.addListenerOnce(map, "tilesloaded", () => {
          // Set the zoom level
          map.setZoom(14);

          // This is just an example of getting and using the map instance!!! don't just blindly copy!
          const bounds = new window.google.maps.LatLngBounds(latLng);
          map.fitBounds(bounds);

          // Add an event listener for zoom_changed
          const zoomListener = map.addListener("zoom_changed", () => {
            // console.log("Zoom level changed on load:", map.getZoom());
            // Additional actions after the zoom level has been updated
          });

          // setMap should be called after setting up the event listener
          setMap(map);

          // Cleanup the listener when the component unmounts or when the map changes
          return () => {
            window.google.maps.event.removeListener(zoomListener);
          };
        });
      }
    },
    [lat, lng]
  );

  const onUnmount = useCallback(function callback(map) {
    // console.log("onunmountcalling");
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} onLoad={onLoad} onUnmount={onUnmount}>
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMaps;
