import { combineReducers } from "@reduxjs/toolkit";
import user from "./duck/userDuck";
import auth from "./duck/authDuck";

const rootReducers = combineReducers({
  user,
  auth,
});

export default rootReducers;
