import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { logOut } from "store/features/patientPortal/authentication/authenticationSlice";
import { logoutRequest } from "redux/reducers/duck/authDuck";
import styles from "style/Navbar.module.css";
import { IMAGES } from "utils/helper/Images";

const Navbar = ({ navlinkText1, navlinkText, navlinkIcon, color }) => {
  const currentUser = useSelector((state) => state.auth.loginData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();

  console.log("currentUser", currentUser);

  const handleLogout = async () => {
    await dispatch(logoutRequest()); // Dispatch the logoutRequest action
    history("/login"); // Navigate to login page
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className={`${styles.navbar} `}>
      <div className={`${styles.navbarLeft} `}>
        <Link to="/patient" className={`${styles.navlink} `}>
          {navlinkText}
        </Link>
        <Link
          to="/patient"
          className={`${styles.navlink} d-flex align-items-center ${
            color ? `${styles.TextcolorChange}` : ""
          }`}
        >
          <span className={`${styles.iconWrapper} `}>{navlinkIcon}</span>
          {navlinkText1}
        </Link>
      </div>
      <div className={`${styles.navbarRightWrapper} `}>
        <div className={`${styles.desktopScreen} `}>
          <ul className={`${styles.navbarRight} m-0`}>
            <li>
              <div className={`${styles.userInfo} `}>
                <img
                  src={IMAGES.ProfileImage}
                  alt="User"
                  className={`${styles.profileImg} `}
                />
                <span className={`${styles.UserName} `}>
                  {currentUser[0].first} {currentUser[0].last}
                </span>
              </div>
            </li>
            <li>
              <Link
                to="/login"
                onClick={handleLogout}
                className={`${styles.logoutBtn} `}
              >
                Logout
              </Link>
            </li>
            <li>
              <Link className={`${styles.settingIcon} `}>
                {/* <AiFillSetting /> */}
                <img src={IMAGES.settingIcon} alt="setting" />
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${styles.mobileScreen}`}>
          <ul className={`${styles.navbarRight} m-0`}>
            <li>
              <div
                className={`${styles.userInfo} ${
                  isDropdownOpen ? styles.active : ""
                }`}
                onClick={handleDropdownToggle}
              >
                <img
                  src={IMAGES.downArrowIcon}
                  alt="User"
                  className={`${styles.dropIcon} `}
                />
                <img
                  src={IMAGES.ProfileImage}
                  alt="User"
                  className={`${styles.profileImg} `}
                />
                <span className={`${styles.UserName} `}>
                  {currentUser[0].first} {currentUser[0].last}
                </span>

                {/* Dropdown Content */}
                {isDropdownOpen && (
                  <div className={styles.dropdownContent}>
                    <ul className={styles.dropdownContentList}>
                      <li>
                        <span>
                          {currentUser[0].first} {currentUser[0].last}
                        </span>
                      </li>
                      <li>
                        <Link
                          to="/login"
                          onClick={handleLogout}
                          className={`${styles.logoutBtn} `}
                        >
                          Logout
                        </Link>
                      </li>
                      <li>
                        <Link className={`${styles.settingIcon} `}>
                          {/* <AiFillSetting /> */}
                          <img src={IMAGES.settingIcon} alt="setting" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
