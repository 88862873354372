import { all, fork } from "redux-saga/effects";
import { watchUserSagas } from "./sagas/userSaga";
import { watchAuthSagas } from "./sagas/authSaga";

const rootSaga = function* () {
  yield all([fork(watchUserSagas)]);
  yield all([fork(watchAuthSagas)]);
};

export default rootSaga;
