// Main.jsx

import CustomButton from "components/Common/CustomButton/CustomButton";
// import { patientRoutes } from "utils/helper/Constants";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "components/Common/TopBar/TopBar";
import styles from "style/WelcomeScreen.module.css";

const WelcomeScreen = () => {
  const history = useNavigate();

  const navigateTo = (route) => {
    history(route);
  };

  return (
    <>
      <TopBar IconItemName="Login" />
      <div className={styles.mainBodyBg}>
        <div className={styles.mainWrapper}>
          <div className={`${styles.LoginWrapper} row`}>
            <div className={`${styles.contentWrapper}`}>
              <h2 className={`${styles.headingText} welcomeMessage`}>
                Welcome To MedFlow Portal
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <div
                className={`${styles.buttonContainer} d-flex flex-column align-items-center`}
              >
                <CustomButton
                  label="Paying as Guest"
                  variant="lightGray"
                  additionalClass={`mb-3 mt-0 ${styles.buttonPayingGuest} text-uppercase`}
                  handleClick={() => navigateTo("/payment-details")}
                ></CustomButton>
                <CustomButton
                  label="Log in into Account"
                  variant="primary"
                  additionalClass={`mb-3 mt-0 ${styles.buttonLoginAccount} text-uppercase`}
                  handleClick={() => navigateTo("/login")}
                />
                <CustomButton
                  label="Creating an Account"
                  variant="secondary"
                  additionalClass={`${styles.buttonCreateAccount} mt-0 text-uppercase`}
                  handleClick={() => navigateTo("/signup")}
                ></CustomButton>
              </div>
              <p className={`text-center ${styles.helpLink}`}>
                <Link to="/forgot-password" className="patient-text-blue">
                  HELP WITH RETRIEVING PASSWORD AND USERNAME
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
