import InputFields from "../../../components/Common/Inputfileds/InputFields";
import { IMAGES } from "../../../utils/helper/Images";
import { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCity } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdEditLocationAlt } from "react-icons/md";
import styles from "../../../style/UpdateInformation.module.css";

const BillingInfo = forwardRef((props, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleBillingSubmit = (data) => {
    console.log("billing info submission", data);
  };

  useImperativeHandle(ref, () => ({
    handleBillingInfoSubmit() {
      handleSubmit(handleBillingSubmit)();
      // Any other logic you want to execute
    },
  }));

  return (
    <div className={`${styles.InsuranceInfoWrapper} w-100`}>
      <div className={`${styles.formFields}`}>
        <form onSubmit={handleSubmit(handleBillingSubmit)}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <Controller
                name="addressOne"
                control={control}
                defaultValue=""
                rules={{ required: "Address is required" }}
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      label="Address 1"
                      type="text"
                      placeholder="Block X Street 0,City"
                      labelStyle={styles.customlable}
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="addressOne"
                      maxLength={100}
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                      CustomIcon={<MdEditLocationAlt />}
                    />
                    {errors.addressOne && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.addressOne.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-md-12 mb-3">
              <Controller
                name="addressTwo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      label="Address 2"
                      type="text"
                      placeholder="Block X Street 0,City"
                      labelStyle={styles.customlable}
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="addressTwo"
                      maxLength={100}
                      handleChange={(e) => field.onChange(e.target.value)}
                      CustomIcon={<MdEditLocationAlt />}
                    />
                    {errors.addressTwo && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.addressTwo.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6 mb-3">
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: "City is required" }}
                render={({ field }) => (
                  <div>
                    <InputFields
                      roundedSm
                      label="City"
                      type="text"
                      placeholder="Your City"
                      labelStyle={styles.customlable}
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="city"
                      maxLength={100}
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                      CustomIcon={<FaCity />}
                    />
                    {errors.city && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.city.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6 ">
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: "State is required" }}
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      label="State"
                      type="text"
                      placeholder="Your State"
                      labelStyle={styles.customlable}
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="state"
                      maxLength={100}
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                      CustomIcon={<FaCity />}
                    />
                    {errors.state && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.state.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6">
              <Controller
                name="zipcode"
                control={control}
                defaultValue=""
                rules={{ required: "Zip Code is required" }}
                render={({ field }) => (
                  <div>
                    <InputFields
                      roundedSm
                      label="Zip Code"
                      type="number"
                      placeholder="XXXX"
                      labelStyle={styles.customlable}
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="zipcode"
                      maxLength={100}
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                      CustomIcon={<FaCity />}
                    />
                    {errors.zipcode && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.zipcode.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6 patient-dropDown-wrapper">
              <InputFields
                label="Country"
                roundedSm
                type="dropDown"
                placeholder="Your Country"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6 patient-phone-input">
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      label="Phone"
                      type="phone"
                      placeholder="your number"
                      value={field.value}
                      index="phone"
                      maxLength={65}
                      handleChange={(e) => field.onChange(e)}
                    />
                    {errors.phone && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.phone.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 mb-3 col-sm-6">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Please enter a valid email address",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <InputFields
                      roundedSm
                      label="Email"
                      type="email"
                      placeholder="Your Email"
                      groupWrapper={styles.fieldsWrapper}
                      value={field.value}
                      index="email"
                      maxLength={25}
                      handleChange={(e) => field.onChange(e.target.value)}
                      required
                      CustomIcon={<HiOutlineMail />}
                    />
                    {errors.email && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {errors.email.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </form>

        <div className={styles.billingInfoCardDetails}>
          <div className={styles.eidtAndCheckedIcon}>
            <img
              src={IMAGES.editicon}
              alt="Edit Icon"
              className={styles.editButton}
            />
            <img
              src={IMAGES.success}
              alt="status"
              className={styles.checkedIcon}
            />
          </div>
          <div>
            <img
              src={IMAGES.visa}
              alt="Visa Logo"
              className={styles.visaLogo}
            />
          </div>
          <div className={styles.cardDetails}>
            <div>
              <label className="text-uppercase">Card Number</label>
              <span className="">XXXX - XXXX - XXXX - 1234</span>
            </div>
            <div>
              <label className={`text-uppercase ${styles.cardHolderName}`}>
                Card Holder
              </label>
              <span className="text-black text-uppercase">Divan Raj</span>
            </div>
            <div>
              <label className={`text-uppercase ${styles.cardHolderName}`}>
                expires
              </label>
              <span className="text-black">09/15</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default BillingInfo;
