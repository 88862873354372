import React, { useState } from "react";
import { IMAGES } from "../../../utils/helper/Images";
import styles from "../../../style/Progress.module.css";

const Progressbar = ({ currentStep }) => {
  const [activeStep, setActiveStep] = useState(currentStep);

  const handleStepClick = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
    // setActiveStep(step);
  };

  return (
    <>
      {" "}
      <h5 className={styles.heading}>Appointment Details</h5>
      <div className={`${styles.visualAppearance}`}>
        <div
          className={`${styles.step} ${
            activeStep > 1 && styles.completedStep
          } ${activeStep === 1 ? styles.activeStep : ""}`}
          onClick={() => handleStepClick(1)}
        >
          <img
            className={`${styles.radioBtn}`}
            src={IMAGES.radiobuttonactive}
            alt="radio"
          />{" "}
          Reason for Visiting
        </div>
        {/* <div className={`${styles.separator}`} /> */}
        <div
          className={`${styles.step} ${
            activeStep > 2 && styles.completedStep
          } ${activeStep === 2 ? styles.activeStep : ""}`}
          onClick={() => handleStepClick(2)}
        >
          <img
            className={`${styles.radioBtn}`}
            src={
              activeStep >= 2
                ? IMAGES.radiobuttonactive
                : IMAGES.radiobuttoninactive
            }
            alt="radio"
          />{" "}
          Enter Address
        </div>
        <div
          className={`${styles.step} ${
            activeStep > 3 && styles.completedStep
          } ${activeStep === 3 ? styles.activeStep : ""}`}
          onClick={() => handleStepClick(3)}
        >
          <img
            className={`${styles.radioBtn}`}
            src={
              activeStep >= 3
                ? IMAGES.radiobuttonactive
                : IMAGES.radiobuttoninactive
            }
            alt="radio"
          />{" "}
          Location, Date, Time
        </div>
        <div
          className={`${styles.step} ${
            activeStep == 4 && styles.completedStep
          } ${activeStep === 4 ? styles.activeStep : ""}`}
          onClick={() => handleStepClick(4)}
        >
          <img
            className={`${styles.radioBtn}`}
            src={
              activeStep == 4
                ? IMAGES.radiobuttonactive
                : IMAGES.radiobuttoninactive
            }
            alt="radio"
          />{" "}
          <span>Enter Personal and Insurance Details</span>
        </div>
      </div>
    </>
  );
};

export default Progressbar;
