// InputFields.jsx

import classNames from "classnames";
import AutoCompleteAddress from "components/Common/AutoCompleteAddress/AutoCompleteAddress";
import PhoneNoInput from "components/Common/PhoneInput/PhoneNoInput";
import moment from "moment";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import styles from "style/InputStyle.module.css";
import { IMAGES } from "utils/helper/Images";

const InputFields = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange, // Use onChange consistently
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    disabled,
    index,
    errorList,
    options,
    isClearable,
    getOptionValue,
    getOptionLabel,
    componentOptions,
    onInputChange,
    required,
    minDate,
    onBlur,
    onFocus,
    maxDate,
    readOnly,
    cssClass,
    customClass,
    roundedSm,
    password,
    shadowSm,
    shadowMd,
    shadowLg,
    bgGray,
    CustomIcon,
    BgColorDtae,
    hideCalendarIcon, // Hide calendeer icon
  } = props;
  const [error, setError] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (errorList && errorList.length > 0) {
      const res = errorList?.includes(index);
      setError(res);
    }
  }, [errorList]);

  useEffect(() => {
    if (options && options.length === 1 && type === "dropDown") {
      handleChange(options[0]); // Use onChange consistently
    }
  }, []);

  const handleTogglePass = () => {
    setShowPass(!showPass);
  };

  const inputStyleClasses = classNames(
    { [styles.shadowSm]: shadowSm },
    { [styles.shadowMd]: shadowMd },
    { [styles.shadowLg]: shadowLg },
    { [styles.bgGray]: bgGray }
  );

  const getInPutFields = (inputType) => {
    switch (inputType) {
      case "phone":
        return (
          <PhoneNoInput
            handleChange={handleChange}
            value={value || ""}
            disabled={disabled}
            cssClass={`${inputStyle} ${error && "errorField"}`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case "address":
        return (
          <AutoCompleteAddress
            cssClass={`${inputStyle} ${disabled ? "disabledAddressBar" : ""} ${
              error && "errorField"
            }`}
            value={value || ""}
            disabled={disabled}
            handleChange={(e, flag) => handleChange(e, flag)} // Use onChange consistently
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case "datePicker":
        return (
          <DatePicker
            className={`${inputStyle} ${roundedSm && "roundedSm"} ${
              error && "errorField"
            } ${cssClass} ${BgColorDtae} patient-date-picker`}
            openCalendarOnFocus={false}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="MM/dd/y"
            calendarIcon={
              hideCalendarIcon ? null : (
                <img src={IMAGES.CalenderIcon} alt="Calendar" />
              )
            }
            onChange={(e) => handleChange(e)} // Use onChange consistently
            disabled={disabled}
            value={
              value && new Date(value) !== "Invalid Date"
                ? moment(value, "YYYY-MM-DD").toDate()
                : null
            }
            maxDate={maxDate}
            minDate={minDate}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      case "dropDown":
        return (
          <Select
            options={options}
            className={`${inputStyle || ""} ${error && "errorField"} ${
              roundedSm && "roundedSm"
            } `}
            blurInputOnSelect={true}
            isClearable={isClearable}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            menuPlacement="auto"
            placeholder={placeholder}
            isDisabled={disabled}
            value={value}
            onChange={(e) => handleChange(e)} // Use onChange consistently
            components={componentOptions}
            onInputChange={onInputChange}
            readOnly={readOnly}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      default:
        return (
          <div className="position-relative patient-cvc-input">
            {CustomIcon && (
              <span className={`${styles.passwordIcon} ${styles.IconCustom}`}>
                {CustomIcon}
              </span>
            )}
            <Form.Control
              type={showPass ? "text" : inputType}
              maxLength={maxlength}
              value={value || ""}
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => handleChange(e)}
              onBlur={onBlur}
              onFocus={onFocus}
              className={`${
                roundedSm && styles.roundedSm
              } ${inputStyleClasses} ${styles.inputStyling} ${inputStyle} ${
                error ? "errorField" : ""
              }`}
              readOnly={readOnly}
            />
            {password && (
              <span className={styles.passwordIcon} onClick={handleTogglePass}>
                {showPass ? (
                  <FaEye color="#000" />
                ) : (
                  <FaEyeSlash color="#7D859C" />
                )}
              </span>
            )}
          </div>
        );
    }
  };

  return (
    <Form.Group
      className={`${
        groupWrapper ? groupWrapper : `d-flex flex-column ${customClass}`
      } patient-input`}
    >
      {label ? (
        <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>
          {`${label} `}
          {required ? (
            <span className="fw-bold fs-6 text-danger">*</span>
          ) : null}
        </Form.Label>
      ) : (
        ""
      )}
      {getInPutFields(type)}
    </Form.Group>
  );
};

export default InputFields;
