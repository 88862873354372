// import Loader from "components/Loader/Loader";
import CustomButton from "components/Common/CustomButton/CustomButton";
import CustomCheckbox from "components/Common/CustomCheckbox/CustomCheckbox";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { FaPhone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { signInPatient } from "store/features/patientPortal/authentication/authenticationActions";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import TopBar from "components/Common/TopBar/TopBar";
import { loginRequest } from "redux/reducers/duck/authDuck";
import styles from "style/Login.module.css";
import { IMAGES } from "utils/helper/Images";

const Login = () => {
  const loginData = useSelector((state) => state.auth.loginData);
  console.log("login data", loginData);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    rememberMe: false,
    termsCondition: false,
  });

  const { handleSubmit, control, formState } = useForm({
    mode: "onChange", // Trigger validation on change
  });

  const { errors } = formState;

  const navigateTo = (route) => {
    history(route);
  };

  const setSuccessMessage = (message) => {
    // Your implementation here
    console.log(message);
  };

  const handleCheckboxChange = (checkboxId, newState) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [checkboxId]: newState,
    }));
  };

  const onSubmit = async (data) => {
    try {
      console.log("login data", data);
      setLoading(true); // Start loader
      dispatch(loginRequest(data));
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    if (loginData) {
      setLoading(false); // Stop loader when sign-up data is received
      navigateTo("/notification");
    }
  }, [loginData]);

  return (
    <>
      <TopBar IconItemName="Pay as Guest" />
      <div className={`${styles.LoginSection} `}>
        {loading && <Loader />}
        <div className={`${styles.LoginWrapper} row`}>
          <div className="col-md-12 col-lg-6">
            <div className={`${styles.contentWrapper} `}>
              <h2 className={`${styles.headingText} `}>
                Welcome To MedFlow Portal
              </h2>
              <p>Log in to view and pay your medical bills easily.</p>
              <div className="px-3">
                <h4>You can login using your Account Number</h4>
                <p>
                  The Account Number contains numbers and letters and can be
                  found at the top right of your statement.
                </p>
              </div>
              <div className={`${styles.contentBox} `}>
                <a
                  href={IMAGES.Loginscreen}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.imgBox} d-block position-relative`}
                >
                  <img src={IMAGES.Loginscreen} alt="pdf" />
                  <img
                    src={IMAGES.openFUllImage}
                    alt="openImage"
                    className={styles.openFullImage}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className={`${styles.loginForm} `}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={`${styles.formWrapper}`}>
                  <p className={`${styles.subHeading}`}>Login</p>
                  <div
                    className={`${styles.Accountfields} mb-4 patient-phone-input`}
                  >
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        pattern: {
                          value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                          message:
                            "Please enter a valid phone number (+x xxx xxx xxxx).",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <InputFields
                            label="Phone"
                            CustomIcon={<FaPhone />}
                            type="phone"
                            placeholder="+1 625 999 3488"
                            value={field.value}
                            index="phone"
                            maxLength={65}
                            handleChange={(e) => {
                              field.onChange(e);
                            }}
                            required
                            roundedSm
                            shadowSm
                          />
                          {errors.phone && (
                            <div
                              className={`${styles.validationmessage} validation-message`}
                            >
                              {errors.phone.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="patientDatePicker">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <InputFields
                            label="Password"
                            type="password"
                            placeholder="***********"
                            value={field.value}
                            maxLength={100}
                            handleChange={(e) => {
                              field.onChange(e);
                            }}
                            required
                            roundedSm
                            password
                            shadowSm
                          />
                          {errors.password && (
                            <div
                              className={`${styles.validationmessage} validation-message`}
                            >
                              {errors.password.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className={`${styles.linksWrapper} text-end mt-3`}>
                    <small>
                      <Link
                        to={"/forgot-password"}
                        className="patient-text-blue mr-3"
                      >
                        Forgot Password?
                      </Link>
                    </small>
                  </div>
                  <div className="mt-4 mb-3">
                    <CustomCheckbox
                      id="rememberMe"
                      label="Remember Me"
                      isChecked={checkboxStates.rememberMe}
                      onChange={(id, isChecked) =>
                        handleCheckboxChange(id, isChecked)
                      }
                      type="default"
                    />
                  </div>

                  <div className={`${styles.rememberfWrapper} mb-3`}>
                    <div className="d-flex align-items-cneter">
                      <CustomCheckbox
                        isChecked={checkboxStates.termsCondition}
                        onChange={handleCheckboxChange}
                        cssClass="text-dark"
                        id="termsCondition"
                        type="default"
                      />
                      <div className="">
                        <small className={styles.readTerms}>
                          I have read, understanding and agree to the &nbsp;{" "}
                          <Link to="#" className="patient-text-blue">
                            Terms of Use,
                          </Link>
                          &nbsp;
                          <Link to="/" className="patient-text-blue">
                            Privacy Policy,
                          </Link>
                          &nbsp;
                          <Link to="/" className="patient-text-blue">
                            Cookie Policy,
                          </Link>
                          &nbsp;
                          <Link to="/" className="patient-text-blue">
                            Confidentiality Notice,
                          </Link>
                          &nbsp; and{" "}
                          <Link to="/" className="patient-text-blue">
                            Consent to Electronic Communication
                          </Link>
                        </small>
                      </div>
                    </div>
                    <div className="mt-3 mt-xl-5">
                      <CustomButton
                        variant="primary"
                        label="VIEW MY ACCOUNT"
                        additionalClass={styles.btnHeight}
                        type="submit"
                        disabled={!formState.isValid}
                      />
                      <small className="d-block text-center mt-3">
                        Don't have an account?{" "}
                        <Link to="/signup" className="patient-text-blue">
                          Sign Up
                        </Link>
                      </small>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
