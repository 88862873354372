import CustomButton from "components/Common/CustomButton/CustomButton";
import InputFields from "components/Common/Inputfileds/InputFields";
import TopBar from "components/Common/TopBar/TopBar";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "style/changePassword.module.css";
import PatientApi from "api/PatientApi";

const ChangePassword = () => {
  const history = useNavigate();
  const location = useLocation();
  const phone = location.state; 

  const { handleSubmit, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
  });

  const { errors } = formState;

  const navigateTo = (route) => {
    history(route);
  };
  console.log("phone", phone);
  const handleResetPassword = async (data) => {
    try {
        
        console.log("forgot phone", data.password);
        const response = await PatientApi.forgotpasswordApi(data);
        console.log("response", response); 
        navigateTo("/change-password");
    } catch (error) {
        console.error("Error resetting password:", error);
        // Handle error as needed, such as displaying an error message to the user
    }
};

  return (
    <>
      <TopBar IconItemName="Change Password" />
      <div className={styles.mainBodyBg}>
        <div className={`${styles.ResetPassword}`}>
          <div className={styles.resetPasswordInner}>
            <form
              className={`${styles.Form}`}
              onSubmit={handleSubmit(handleResetPassword)}
            >
              <h4 className="h4">Enter New Password</h4>
              <p className={`${styles.Subtitle}`}>
                To change your password, please fill in the field below.
                <br></br>Your password must contain at least 8 characters, it
                must also include at least<br></br> one uppercase letter, one
                lowercase letter, one number, and one special character.
              </p>
              <div className={styles.formFields}>
              <div className={`${styles.FormField} mb-4`}>
                  <Controller
                    name="otp"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "OTP is required",
                      pattern: {
                        value: /^\d{6}$/,
                        message: "OTP must be a 6-digit number.",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          label="OTP"
                          type="text"
                          placeholder="Enter OTP"
                          value={field.value}
                          handleChange={(e) => {
                            setValue("otp", e.target.value);
                            field.onChange(e);
                          }}
                          required
                          roundedSm
                        />
                        {errors.otp && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.otp.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                  </div>
                <div className={`${styles.FormField} mb-3`}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "New Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long.",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          label="New Password"
                          type="password"
                          placeholder="New Password"
                          value={field.value}
                          handleChange={(e) => {
                            setValue("password", e.target.value);
                            field.onChange(e);
                          }}
                          required
                          roundedSm
                          password
                        />
                        {errors.password && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.password.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={`${styles.FormField} mb-4`}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Confirm password is required",
                      validate: {
                        matchesNewPassword: (value) =>
                          value === getValues("password") ||
                          "Passwords do not match",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          label="Confirm Password"
                          type="password"
                          placeholder="Confirm Password"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e);
                          }}
                          required
                          roundedSm
                          password
                          shadowSm
                        />
                        {errors.confirmPassword && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.confirmPassword.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <CustomButton
                  label="Change Password"
                  additionalClass={styles.ResetButton}
                  disabled={!formState.isValid}
                  type="submit"
                  variant="primary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
