import AddAddress from "modules/patientsPortal/Appointments/AddAddress/AddAddress";
import AppointmentLocationTime from "modules/patientsPortal/Appointments/AppointmentLocationTime/AppointmentLocationTime";
import Appointments from "modules/patientsPortal/Appointments/Appointments";
import PersonalInsuranceDetails from "modules/patientsPortal/Appointments/Personal&InsuranceDetails/Personal&InsuranceDetails";
import ReviewAppointment from "modules/patientsPortal/Appointments/ReviewAppointment/ReviewAppointment";
import Notification from "modules/patientsPortal/Notification/Notification";
import BillingInfo from "modules/patientsPortal/UpdateInformation/BillingInfo";
import InsuranceInfo from "modules/patientsPortal/UpdateInformation/InsuranceInfo";
import PasswordAndSecurity from "modules/patientsPortal/UpdateInformation/PasswordAndSecurity";
import UpdateInformation from "modules/patientsPortal/UpdateInformation/UpdateInformation";
import PaymentHistory from "modules/patientsPortal/paymentHistory/PaymentHistory";
import ViewResults from "modules/patientsPortal/viewResults/ViewResults";

const patientRoutes = [
  {
    path: "/notification",
    component: Notification,
  },
  {
    path: "/appointments",
    component: Appointments,
  },
  {
    path: "/payment-history",
    component: PaymentHistory,
  },
  {
    path: "/update-information",
    component: UpdateInformation,
  },
  {
    path: "/results",
    component: ViewResults,
  },
  {
    path: "/add-address",
    component: AddAddress,
  },
  {
    path: "/review-appointment",
    component: ReviewAppointment,
  },
  {
    path: "/appointment-location",
    component: AppointmentLocationTime,
  },
  {
    path: "/personal-insurance-details",
    component: PersonalInsuranceDetails,
  },

  {
    path: "/billing-info",
    component: BillingInfo,
  },
  {
    path: "/password-security",
    component: PasswordAndSecurity,
  },
  {
    path: "/insurance-info",
    component: InsuranceInfo,
  },
];

export { patientRoutes };
