import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
// import "../src/style/global.scss";
import { Amplify } from "aws-amplify";
import awsExports from "aws-exports"; // The path to your aws-exports file
import { Spinner } from "react-bootstrap";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "../src/assets/css/style.css";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
        pauseOnHover
      />
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Suspense>
  </Provider>
);

reportWebVitals();
