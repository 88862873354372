import PatientApi from "api/PatientApi";
import InputFields from "components/Common/Inputfileds/InputFields";
import Loader from "components/Common/Loader/Loader";
import Navbar from "components/Common/Navbar/Navbar";
// import PDF from "components/Common/PDF/PDF";
import MainTable from "components/Common/Table/MainTable";
import { useEffect, useState } from "react";
import styles from "style/ViewResults.module.css";
import { TABLE_COLUMN_TYPES } from "utils/helper/Constants";
import { IMAGES } from "utils/helper/Images";

const ViewResults = () => {
  const [allEmployees, setAllEmployees] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tableColumns = [
    // {
    //   itemKey: "inf",
    //   isCheck: true,
    //   textOverflow: "visible",
    //   id: 1,
    //   type: "text",

    //   customCellRenderer: true
    // },
    {
      itemKey: "resultDate",
      title: "Collected Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 1,
      width: "100%",
      type: TABLE_COLUMN_TYPES.dateTime,
    },
    {
      itemKey: "resultDateTime",
      title: "Processed Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 2,
      width: "100%",
      type: TABLE_COLUMN_TYPES.dateTime,
    },

    {
      itemKey: "test_type",
      title: "Test Type",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 3,
      width: "100%",
      type: "text",
    },
    {
      itemKey: "result",
      title: "Result",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 4,
      width: "100%",
      type: "text",
    },
    {
      itemKey: "barcode",
      title: "Accession Number",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 5,
      width: "100%",
      type: "text",

      //   customCellRenderer: true,
    },
    {
      itemKey: "view",
      title: "View",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 6,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },
  ];

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const res = await PatientApi.getTestByEmployeeAPI({
        params: { employeeID: "2350452e-18ad-4dd8-a15d-3ec2edf4019a" },
      });
      const response = res.data.rows;
      setOriginalData(response);
      setAllEmployees(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    if (searchedValue === "") {
      setSearchedItem("");
      setAllEmployees(originalData);
      return;
    }
    const filteredEmployees = originalData.filter((employee) => {
      return (
        (employee.test_type &&
          employee.test_type.toLowerCase().includes(searchedValue)) ||
        (employee.result &&
          employee.result.toLowerCase().includes(searchedValue)) ||
        (employee.barcode &&
          employee.barcode.toLowerCase().includes(searchedValue))
      );
    });
    setSearchedItem(searchedValue); // Update searched item in state
    setAllEmployees(filteredEmployees);
  };
  const handleClick = async (id) => {
    // await PatientApi.getTestDetailsByID(id);
    const res = await PatientApi.getTestRecordByID({ id: id, name: "test" });
    console.log("res", res);
  };

  const customColumnCellRenderer = (column, row) => {
    if (column.itemKey === "view") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          {/* <label style={{ color: "red", cursor: "pointer" }}>Remove</label> */}
          <div onClick={() => handleClick(row.id)}>
            <img
              style={{
                width: "25px",
              }}
              src={IMAGES.eyeIcon}
              alt="bills"
            />
          </div>
        </td>
      );
    }
    // Handle other columns if needed
  };
  const customCssClass = "Patient-history-table";

  return (
    <div className={`w-100 ${styles.mainWrapper}`}>
      <Navbar
        navlinkText="Results"
        navlinkText1="View Result"
        navlinkIcon={
          <img
            className={styles.HomeImg}
            src={IMAGES.DarkHomeIcon}
            alt="home"
          />
        }
      />
      <div className="px-3">
        <div
          className={`${styles.searchPanelWrapper} d-flex justify-content-between align-items-center`}
        >
          <h2 className={`${styles.pageTitle} mb-0`}>View Result</h2>
          <div className="d-flex align-items-center">
            <InputFields
              type="text"
              placeholder="Search"
              handleChange={(e) => handleSearch(e)}
              value={searchedItem}
            />
          </div>
        </div>
        <div className="mt-3">
          {isLoading ? (
            <Loader />
          ) : allEmployees.length === 0 ? (
            <p className="text-center fs-4">No data found</p>
          ) : (
            <MainTable
              columns={tableColumns}
              rows={allEmployees}
              cssClass={customCssClass}
              customColumnCellRenderer={customColumnCellRenderer}
            />
          )}
          {/* {showPdf && (
            <PDFViewer className="pdfView">
              <PDF
                test={testPdf}
                testsLab={testsLab}
                parseTestResultForPDF={appContext.parseTestResultForPDF}
              />
            </PDFViewer>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ViewResults;
