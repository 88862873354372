import React from "react";
import styles from "style/CustomCheckbox.module.css";
const CustomCheckbox = (props) => {
  const { id, label, isChecked, onChange, cssClass, type } = props;
  const handleChange = () => {
    onChange(id, !isChecked);
  };
  return (
    <>
      {type === "default" && (
        <div
          className={type && `${styles.formGroup} ${styles.defaultCheckbox}`}
        >
          <input
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={handleChange}
          />
          <label for={id}>{label && label}</label>
        </div>
      )}
      {type === "filled" && (
        <div className={type && `${styles.formGroup} ${styles.filledCheckbox}`}>
          <input
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={handleChange}
          />
          <label for={id} className={cssClass}>
            {label && label}
          </label>
        </div>
      )}
      {type === "styled" && (
        <div className={type && `${styles.formGroup} ${styles.styledCheckbox}`}>
          <input
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={handleChange}
          />
          <label for={id} className={cssClass}>
            {label && label}
          </label>
        </div>
      )}
    </>
  );
};

export default CustomCheckbox;
