import CustomButton from "components/Common/CustomButton/CustomButton";
import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import MainTable from "components/Common/Table/MainTable";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "style/Notification.module.css";
import { item } from "utils/helper/Constants";
import { IMAGES } from "utils/helper/Images";

const Notification = () => {
  const history = useNavigate();
  
  const navigateTo = (route) => {
    history(route);
  };
  const tableColumns = [
    // {
    //   itemKey: "inf",
    //   isCheck: true,
    //   textOverflow: "visible",
    //   id: 1,
    //   type: "text",

    //   customCellRenderer: true
    // },
    {
      itemKey: "email",
      title: "Email",
      textAlign: "left",
      isCheck: true,
      textOverflow: "visible",
      id: 1,
      width: "100%",
      type: "text",
    },
    {
      itemKey: "alerts",
      title: "Alerts",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 2,
      width: "100%",
      type: "text",
      customCellRenderer: true,
    },

    {
      itemKey: "paperless",
      title: "Paperless",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 3,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
    {
      itemKey: "status",
      title: "Status",
      textAlign: "left",
      isCheck: true,
      textOverflow: "visible",
      id: 4,
      width: "100%",
      type: "text",
    },
    {
      itemKey: "action",
      title: "Action",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 5,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
  ];

  const tableRows = [
    {
      id: 1,
      email: "m.carmen@gmail.com",
      alerts: "",
      paperless: "",
      status: "Pending Confirmation",
      action: { isResend: true, text: "Resend" },
    },
    {
      id: 1,
      email: "m.carmen@gmail.com",
      alerts: "",
      paperless: "",
      status: "Pending Confirmation",
      action: { isResend: false, text: "Renove" },
    },
  ];
  const customColumnCellRenderer = (column, row) => {
    if (column.itemKey === "alerts") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            minWidth: "20px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {/* Switch button */}
          <label className={styles.switch}>
            <input type="checkbox" checked />
            <span className={`${styles.slider} ${styles.round}`}></span>
          </label>
        </td>
      );
    }
    if (column.itemKey === "paperless") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            minWidth: "20px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {/* Switch button */}
          <label className={styles.switch}>
            <input type="checkbox" />
            <span className={`${styles.slider} ${styles.round}`}></span>
          </label>
        </td>
      );
    }
    if (column.itemKey === "action") {
      const isResend = row.action.isResend;
      const buttonClass = isResend ? styles.resendButton : styles.renoveButton;

      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            minWidth: "20px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {/* Display text 'Active' or 'Inactive' based on isActive property */}
          <span className={isResend ? styles.resendText : styles.renoveText}>
            {row.action.text}
          </span>
          {/* Add button */}
          {/* <button
            className={`${styles.addButton} ${buttonClass}`}
            onClick={handleClick}
          >
           
            {isActive ? 'Active' : 'Inactive'}
          </button> */}
        </td>
      );
    }
    // Handle other columns if needed
  };
  const handleClick = (e) => {
    // Handle click event for the button
  };

  const customCssClass = "Patient-history-table";

  // table data
  const data = [
    // { field1: 'Value1', field2: 'Value2', field3: 'Value8', field4: 'Value1', field5: 'Value2', field6: 'Value8', field7: 'Value2', field8: 'Value8' },
  ];

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`${styles.rightmainWrapper} `}>
        <Navbar
          color
          navlinkText="Notifications"
          navlinkText1="My Notifications"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />
        {/* <Dashbar pageName="Payment History" /> */}
        <div className={`${styles.pageWrapper}`}>
          <div className={`${styles.topText} px-2`}>
            <h4 className={`${styles.heading} `}>Manage My Notifications</h4>
            <p className={`${styles.subText} `}>
              Configure email and text alerts to get billing updates and to
              lookup your account if you can’t log in.
            </p>
          </div>
          <div className={`row flex-column-reverse flex-md-row mb-4 mb-md-0`}>
            <div className="col-md-8 col-lg-8 col-xl-9 notification-data-table">
              <div className={`${styles.alertsWrpper}`}>
                <div className={`${styles.emailWrapper} mb-2`}>
                  <p className={`${styles.headingText}`}>Email Alerts</p>
                  <p className={`${styles.subtextaletr}`}>
                    Received billing notifications via email
                  </p>
                </div>
                <div className={`${styles.emailWrapper} mb-5`}>
                  <p className={`${styles.headingText}`}>Paperless Billing</p>
                  <p className={`${styles.subtextaletr}`}>
                    To enroll, add your email or turn on paperless for your
                    existing email. We will send a verification email to your
                    address. Clicking the link in the email will enable
                    Paperless.
                  </p>
                </div>
                <div>
                  <div
                    className={`${styles.addEmailbtn} d-flex justify-content-end mb-2`}
                  >
                    <CustomButton
                      variant="success"
                      additionalClass={styles.emailBtnWidth}
                      label="Add Email"
                      // disabled={true}
                      icon={FaPlus}
                      rounded
                      handleClick={handleClick}
                    />
                  </div>

                  <MainTable
                    columns={tableColumns}
                    rows={tableRows}
                    customColumnCellRenderer={customColumnCellRenderer}
                    cssClass={customCssClass}
                    // tools={[TABLE_QUICK_TOOLS.checkbox]}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3">
              <RightSideBar
                value="162.85"
                phoneNumber="(909) 718-4592"
                faxNumber="(909) 469-6718"
                email="billing@biolabaratory.net"
                time="8:00 AM - 5:00 PM PST-M-F"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div>PaymentHistory</div> */}
    </div>
  );
};

export default Notification;
