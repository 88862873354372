import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../utils/helper/Images";
import CustomButton from "../../../components/Common/CustomButton/CustomButton";
import Progressbar from "../../../components/Common/Progressbar/Progressbar";
import styles from "../../../style/RightSideBar.module.css";

const RightSideBar = ({
  value,
  phoneNumber,
  faxNumber,
  time,
  email,
  progressBar,
  lightGray,
  currentStep,
}) => {
  const history = useNavigate();
  const navigateTo = (route) => {
    history.push(route);
  };
  return (
    <div className={` ${styles.rightSidebar} `}>
      {progressBar ? (
        <Progressbar currentStep={currentStep} />
      ) : (
        <div className={styles.Rightswrapper}>
          <span className={`patient-text-blue ${styles.AmountDue} pt-2 px-3`}>
            Amount Due
          </span>
          <p className={`${styles.amount} mb-4 px-3`}>${value}</p>
          <div className="px-3">
            <CustomButton
              label="MAKE A PAYMENT"
              handleClick={() => navigateTo("/payment-details")}
              variant={lightGray}
            />
          </div>
          <div className="px-4">
            <p className={`${styles.subheading} mt-4`}>Know your rights:</p>
            <p className={`${styles.subdetails} `}>
              If our services were performed by an out-of- network provider at
              an in - network facility, your plan should provide in network
              coverage. Please contact us if you have questions regarding your
              bill.
              <Link to="/" className="patient-text-blue mt-2 mb-0 d-block">
                Click here for Information
              </Link>
            </p>
          </div>
        </div>
      )}

      <div className={`${styles.contactDetails} px-4`}>
        <p className={`${styles.subheading} `}>Billing Questions</p>
        <ul className={`${styles.detailsList} list-unstyled`}>
          <li>Tel: {phoneNumber}</li>
          <li>Fax: {faxNumber}</li>
          <li>Email: {email}</li>
          <li>{time}</li>
        </ul>
      </div>
      <div className={`${styles.sprtr}`}></div>
      <div className={`${styles.languageWrapper} position-relative py-4 px-4`}>
        <Link className={`${styles.langaugeText} mb-0`}>
          <img src={IMAGES.language} alt="Language" className="pe-2" /> English
        </Link>
      </div>
    </div>
  );
};
export default RightSideBar;
