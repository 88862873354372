import Navbar from "components/Common/Navbar/Navbar";
import RightSideBar from "components/Common/RightSideBar/RightSideBar";
import MainTable from "components/Common/Table/MainTable";
import SearchPanel from "components/Common/searchPanel/SearchPanel";
import { Link, useNavigate } from "react-router-dom";
import styles from "style/PaymentHistory.module.css";
import { TABLE_QUICK_TOOLS } from "utils/helper/Constants";
import { IMAGES } from "utils/helper/Images";

const PaymentHistory = () => {
  const history = useNavigate();

  const navigateTo = (route) => {
    history(route);
  };
  const tableColumns = [
    {
      itemKey: "date",
      title: "Date",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 2,
      width: "100%",
      type: "text",
    },

    {
      itemKey: "transcation",
      title: "Transcation ID",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 3,
      width: "100%",
      type: "text",

      //  customCellRenderer: true
    },
    {
      itemKey: "method",
      title: "Method",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 4,
      width: "100%",
      type: "text",
    },
    {
      itemKey: "amount",
      title: "Amount",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 5,
      width: "100%",
      type: "text",

      //  customCellRenderer: true
    },
    {
      itemKey: "superbill",
      title: "Super Bill",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 6,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
    {
      itemKey: "invoice",
      title: "Invoice",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 7,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
    {
      itemKey: "status",
      title: "Status",
      textAlign: "center",
      isCheck: true,
      textOverflow: "visible",
      id: 8,
      width: "100%",
      type: "text",

      customCellRenderer: true,
    },
  ];

  const tableRows = [
    {
      id: 1,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 2,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 3,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 4,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 5,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 6,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 7,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 8,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 9,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
    {
      id: 10,
      date: "06/11/2023",
      transcation: "1235-6198-1846-2984",
      amount: "$23.00",
      method: "Credit card",
    },
  ];
  const customColumnCellRenderer = (column, row) => {
    if (column.itemKey === "superbill") {
      return (
        // <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} key={column.itemKey}>
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          <Link to="/">
            <img
              style={{
                width: "20px",
              }}
              src={IMAGES.rateIcon}
              alt="bills"
            />
          </Link>
        </td>
        // </div>
      );
    }
    if (column.itemKey === "invoice") {
      return (
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          {/* <label style={{ color: "red", cursor: "pointer" }}>Remove</label> */}
          <Link to="/">
            <img
              style={{
                width: "25px",
              }}
              src={IMAGES.eyeIcon}
              alt="bills"
            />
          </Link>
        </td>
        // </div>
      );
    }
    if (column.itemKey === "status") {
      return (
        // <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} key={column.itemKey}>
        <td
          className="ellipsis text-center"
          style={{
            textAlign: column.textAlign,
            textOverflow: column.textOverflow,
          }}
        >
          {/* <label style={{ color: "red", cursor: "pointer" }}>Remove</label> */}

          <button className="btn status-btn">Paid</button>
        </td>
        // </div>
      );
    }
  };
  // Your other props and customizations
  const customCssClass = "Patient-history-table";

  return (
    <div className={`d-flex justify-content-start ${styles.mainWrapper}`}>
      <div className={`${styles.rightmainWrapper} `}>
        <Navbar
          navlinkText="Portal"
          navlinkText1="Payment List"
          navlinkIcon={
            <img
              className={styles.HomeImg}
              src={IMAGES.DarkHomeIcon}
              alt="home"
            />
          }
        />
        <SearchPanel pageName="Payment History" />
        <div className="page-wrapper row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <MainTable
              columns={tableColumns}
              // selectedRows={selectedRows}
              rows={tableRows}
              cssClass={customCssClass}
              customColumnCellRenderer={customColumnCellRenderer}
              styled
              tools={[TABLE_QUICK_TOOLS.checkbox]}
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mt-sm-2 mt-md-0">
            <RightSideBar
              value="162.85"
              phoneNumber="(909) 718-4592"
              faxNumber="(909) 469-6718"
              email="billing@biolabaratory.net"
              time="8:00 AM - 5:00 PM PST-M-F"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
