import React from "react";
// import Auth from "components/auth";
// import PdfViewer from "components/pdfViewer";
import Approutes from "./Routes";
// import SignUp from "modules/patientsPortal/signUp/signUp"

function App() {
  // const pdfUrl =
  //   "https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf";

  return (
    <>
      {/* <PdfViewer url={pdfUrl} /> */}
      <Approutes />
    </>
  );
}

export default App;
